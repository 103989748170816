import Swal from 'sweetalert2';
import logger from './logger.service';

type Entity = 'Usuario' | 'Direccion' | 'Card' | 'Orden' | 'Banner' | 'Carousel' | 'Server';
/**
 * Function para personalizar los errores provenientes de los endpoints.
 * @param {Entity} entity
 * @param {*} error
 */
export default function apiErrorHandler(entity: Entity, error) {
   // Client Errors
   if (error.type) {
      switch (entity) {
         case 'Usuario':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Direccion':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Card':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Orden':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Banner':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Carousel':
            Swal.fire('Error!', error.message, 'error');
            break;
      }
   }

   // Server Errors
   if (error.status === 500) {
      switch (entity) {
         case 'Server': {
            // Enviar error a sentry
            logger.log(error.response);
            // Mostrar error generico al usuario
            Swal.fire('Ocurrió un error inesperado', 'Espere un momento e intente de nuevo.', 'error');
            break;
         }
      }
   }
}

import IMAGE_PLACEHOLDER from '../assets/images/nopicture-placeholder.jpg';

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
export const MAX_FILE_SIZE = 150 * 1024; //150KB (bytes * 1024)
export const CATEGORIAS_EXCLUIDAS = [
   '',
   '0',
   'Línea',
   'ABARROTES',
   'ARTICULOS NUEVOS',
   'REMATE',
   'SERVICIOS Y NOTAS DE CREDITO',
   'USO INTERNO',
];

export const PRODUCTO_IMAGE_PLACEHOLDER = IMAGE_PLACEHOLDER;

export const IVA = 1.08; // 8% IVA del 8%
export const IVA_PORCENTAJE = 0.08; // 8% IVA del 8%
export enum METODOS_ENTREGA {
   EN_DOMICILIO = 1,
   EN_SUCURSAL,
}
export const METODOS_ENTREGA_NOMBRES = {
   1: 'Envío a domicilio',
   2: 'Entrega en sucursal',
};
export enum METODOS_PAGO {
   TARJETA = 1,
   EFECTIVO,
   OXXO,
   CREDITO_MEDINA,
   EFECTIVO_DOMICILIO,
   EFECTIVO_SUCURSAL,
   TARJETA_SUCURSAL,
   PAYPAL,
}

export enum ORDEN_STATUS {
   PENDIENTE = 1,
   PAGADA,
   REEMBOLSADA,
   ENTREGADA,
}
export const ORDEN_STATUS_NOMBRE = {
   1: 'Pendiente',
   2: 'Pagada',
   3: 'Reembolsada',
   4: 'Entregada',
};
export const METODOS_PAGO_DESC = {
   1: 'Pago con tarjeta',
   2: 'Pago en efectivo',
   3: 'OXXO',
   4: 'Credito Medina',
   5: 'Pago en efectivo en domicilio',
   6: 'Pago en efectivo en sucursal',
   7: 'Pago con tarjeta en sucursal',
   8: 'Paypal',
};

import * as Sentry from '@sentry/browser';

function init() {
   Sentry.init({
      dsn: 'https://a62e9f9561014e29a6136644881372ef@o337685.ingest.sentry.io/5254351',
   });
}

function log(error) {
   Sentry.captureException(error);
}

export default {
   init,
   log,
};

import React, { useState } from 'react';
import { Card, Form as RBForm, Alert as RBAlert, Row, Col } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
// Mis componentes
import http from 'services/http.service';
import useScrollTop from 'hooks/useScrollTop';
import ErrorFeedback from 'components/shared/ErrorFeedback';

const initialValues = {
   nombre: '',
   telefono: '',
   email: '',
   mensaje: '',
};

export interface ContactoProps {
   setCartCounter: React.Dispatch<any>;
}

const Contacto: React.FC<ContactoProps> = () => {
   const [genericAlert, setGenericAlert] = useState<any>({
      isOpen: false,
      variant: '',
      message: '',
   });

   const contactoSchema = Yup.object().shape({
      nombre: Yup.string().min(6, 'Mínimo 6 caracteres').max(100, 'Máximo 100 caracteres').trim().required('Campo requerido'),
      telefono: Yup.string().min(7, 'Minimo 7 caracteres').max(11, 'Máximo 11 caracteres').trim().required('Campo requerido'),
      email: Yup.string().email('Escriba un correo válido').trim().required('Campo requerido'),
      mensaje: Yup.string().min(10, 'Minimo 10 caracteres').max(500, 'Minimo 500 caracteres').trim().required('Campo requerido'),
   });

   useScrollTop();

   const handleFormSubmit = async (values, actions) => {
      try {
         const body = {
            ...values,
         };
         await http.post('users/contacto', body);

         actions.resetForm();
         setGenericAlert({
            isOpen: true,
            variant: 'success',
            message: 'Tu mensaje ha sido enviado correctamente. Nos comunicaremos contigo a la brevedad posible',
         });
         setTimeout(() => {
            clearAlert();
         }, 3000);

         actions.setSubmitting(false);
      } catch (error) {
         console.error(error);
         actions.setSubmitting(false);
         setGenericAlert({
            isOpen: false,
            variant: 'danger',
            message: error.message,
         });
      }
   };

   const clearAlert = () => {
      setGenericAlert({
         isOpen: false,
         variant: '',
         message: '',
      });
   };

   return (
      <div className='container my-5'>
         <Card className='card-shadow'>
            <Card.Body>
               {genericAlert.isOpen ? (
                  <RBAlert className='text-left' variant={genericAlert.variant}>
                     {genericAlert.message}
                  </RBAlert>
               ) : null}
               <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  validationSchema={contactoSchema}
                  onSubmit={handleFormSubmit}
                  render={({ isSubmitting }: FormikProps<any>) => (
                     <Form className='agregar-metodo-pago-form'>
                        <RBForm.Group controlId='nombre'>
                           <RBForm.Label>Nombre</RBForm.Label>
                           <Field name='nombre'>
                              {({ field }) => <RBForm.Control {...field} type='text' placeholder='Escribe tu nombre' />}
                           </Field>
                           <ErrorMessage name='nombre' component={ErrorFeedback} />
                        </RBForm.Group>

                        <Row>
                           <Col md='6'>
                              <RBForm.Group controlId='telefono'>
                                 <RBForm.Label>Teléfono</RBForm.Label>
                                 <Field name='telefono'>
                                    {({ field }) => <RBForm.Control {...field} type='text' placeholder='' />}
                                 </Field>
                                 <ErrorMessage name='telefono' component={ErrorFeedback} />
                              </RBForm.Group>
                           </Col>
                           <Col md='6'>
                              <RBForm.Group controlId='email'>
                                 <RBForm.Label>Correo Electrónico</RBForm.Label>
                                 <Field name='email'>
                                    {({ field }) => (
                                       <RBForm.Control {...field} type='text' placeholder='Escribe tu direccion de email' />
                                    )}
                                 </Field>
                                 <ErrorMessage name='email' component={ErrorFeedback} />
                              </RBForm.Group>
                           </Col>
                        </Row>

                        <RBForm.Group controlId='mensaje'>
                           <RBForm.Group controlId='mensaje'>
                              <RBForm.Label>Mensaje</RBForm.Label>
                              <Field name='mensaje'>
                                 {({ field }) => (
                                    <RBForm.Control
                                       {...field}
                                       as='textarea'
                                       rows='3'
                                       placeholder='Escribenos tus dudas o solicitudes'
                                    />
                                 )}
                              </Field>
                              <ErrorMessage name='mensaje' component={ErrorFeedback} />
                           </RBForm.Group>
                        </RBForm.Group>

                        <div className='text-center'>
                           <Button
                              variant='primary'
                              type='submit'
                              className='btn-wide font-weight-bold mt-5 px-5'
                              disabled={isSubmitting}>
                              {isSubmitting ? <FontAwesomeIcon icon={faSpinner} pulse size='lg' /> : 'Enviar Correo'}
                           </Button>
                        </div>
                     </Form>
                  )}
               />
            </Card.Body>
         </Card>
      </div>
   );
};

export default Contacto;

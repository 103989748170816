import React, { useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import scriptLoader from 'react-async-script-loader';
import logger from 'services/logger.service';
import { getPrecioNeto } from 'utils';

declare global {
   interface Window {
      paypal: any;
   }
}
interface scriptLoaderProps {
   isScriptLoaded: boolean;
   isScriptLoadSucceed: boolean;
}
export interface PayPalButtonProps extends scriptLoaderProps {
   orden: any;
   onCreatePayPalOrden: (paypalOrdenID) => Promise<void>;
   setCartCounter: React.Dispatch<any>;
   // subtotal: number;
   total: number;
}

const PayPalButton: React.FC<PayPalButtonProps> = ({
   orden,
   onCreatePayPalOrden,
   // subtotal,
   total,
   isScriptLoaded,
   isScriptLoadSucceed,
}) => {
   const paypalRef = useRef<HTMLDivElement>(null);

   useEffect(() => {
      if (isScriptLoaded && isScriptLoadSucceed) {
         window.paypal
            .Buttons({
               style: {
                  shape: 'rect',
                  color: 'gold',
                  layout: 'horizontal',
                  label: 'checkout',
               },
               createOrder,
               onApprove,
               onError,
            })
            .render(paypalRef.current);
      }
   }, [isScriptLoaded, isScriptLoadSucceed]);

   const createOrder = async (data, actions) => {
      const items = orden.carrito.map(producto => {
         return {
            name: producto.descripcion.length > 127 ? `${producto.descripcion.substring(0, 123)}...` : producto.descripcion,
            unit_amount: getPrecioNeto(producto, producto.cantidad),
            quantity: producto.cantidad,
            category: 'PHYSICAL_GOODS',
         };
      });
      return actions.order.create({
         purchase_units: [
            {
               description: 'Pedido en www.medinaferreterias.com',
               amount: {
                  currency_code: 'MXN',
                  value: Number(total.toFixed(2)),
                  // breakdown: {
                  //    item_total: { currency_code: 'MXN', value: Number(subtotal.toFixed(2)) },
                  //    tax_total: { currency_code: 'MXN', value: IVA_PORCENTAJE },
                  // },
                  items,
               },
            },
         ],
      });
   };
   const onApprove = async (data, actions) => {
      const capturedOrder = await actions.order.capture();

      await onCreatePayPalOrden(capturedOrder.id);
   };
   const onError = error => {
      logger.log(error);
      Swal.fire('Error', 'Ocurrio un error al procesar tu compra, por favor intenta de nuevo.', 'error');
   };

   return isScriptLoaded && isScriptLoadSucceed ? <div ref={paypalRef} /> : null;
};

export default scriptLoader([
   `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_API_KEY}&currency=MXN&locale=es_MX`,
])(PayPalButton);

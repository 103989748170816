import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import Card from 'react-bootstrap/Card';
import RBAlert from 'react-bootstrap/Alert';
import RBForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import http from 'services/http.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ErrorFeedback from 'components/shared/ErrorFeedback';

const initialValues = {
   nombres: '',
   apellidos: '',
   telefono: '',
   email: '',
   password: '',
   confirmPassword: '',
};

const NewAccount: React.FC = () => {
   let history = useHistory();
   const [genericAlert, setGenericAlert] = useState<any>({
      isOpen: false,
      variant: '',
      message: '',
   });

   const loginSchema = Yup.object().shape({
      nombres: Yup.string().min(3, 'Mínimo 3 caracteres').max(200, 'Maximo 200 digitos').required('Campo requerido'),
      apellidos: Yup.string().min(3, 'Mínimo 3 caracteres').max(200, 'Maximo 200 digitos').required('Campo requerido'),
      telefono: Yup.string().min(7, 'Mínimo 7 digitos').max(10, 'Maximo 10 digitos').required('Campo requerido'),
      email: Yup.string().email('Escriba un email válido').required('Campo requerido'),
      password: Yup.string().min(6, 'Mínimo 6 caracteres').max(50, 'Maximo 50 caracteres').required('Campo requerido'),
      confirmPassword: Yup.string()
         .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
         .required('Campo requerido'),
   });

   useEffect(() => {
      return () => clearInterval(redirectInterval);
   }, []);
   let redirectInterval;
   const handleFormSubmit = async (values, actions) => {
      try {
         const body = {
            nombres: values.nombres,
            apellidos: values.apellidos,
            telefono: values.telefono,
            email: values.email,
            password: values.password,
            confirmPassword: values.confirmPassword,
         };
         await http.post('users', body);

         setGenericAlert({
            isOpen: true,
            variant: 'success',
            message: 'Tu registro se ha completado correctamente. Seras redireccionado...',
         });
         redirectInterval = setInterval(() => {
            history.push('/entrar');
         }, 3 * 1000);
      } catch (error) {
         actions.setSubmitting(false);
         if (error.type === 'Usuario/EmailInUse') {
            setGenericAlert({
               isOpen: true,
               variant: 'error',
               message: error.message,
            });
         }
      }
   };

   const handleCloseAlert = () => {
      setGenericAlert({
         isOpen: false,
         variant: '',
         message: '',
      });
   };

   return (
      <div className='auth'>
         <Card className='auth-card'>
            <Card.Header className='bg-white text-center text-primary'>Crear una cuenta</Card.Header>
            <Card.Body className='px-5 pb-5'>
               <div className=''>
                  {genericAlert.isOpen ? (
                     <RBAlert className='text-left' variant={genericAlert.variant} onClose={handleCloseAlert} dismissible>
                        {genericAlert.message}
                     </RBAlert>
                  ) : null}
                  <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={handleFormSubmit}>
                     {({ isSubmitting }: FormikProps<any>) => (
                        <Form>
                           <RBForm.Group controlId='nombres'>
                              <RBForm.Label>Nombre</RBForm.Label>
                              <Field name='nombres'>
                                 {({ field }) => <RBForm.Control {...field} type='text' placeholder='Escribe tu nombre' />}
                              </Field>
                              <ErrorMessage name='nombres' component={ErrorFeedback} />
                           </RBForm.Group>

                           <RBForm.Group controlId='apellidos'>
                              <RBForm.Label>Apellidos</RBForm.Label>
                              <Field name='apellidos'>
                                 {({ field }) => <RBForm.Control {...field} type='text' placeholder='Escribe tus apellidos' />}
                              </Field>
                              <ErrorMessage name='apellidos' component={ErrorFeedback} />
                           </RBForm.Group>

                           <RBForm.Group controlId='telefono'>
                              <RBForm.Label>Teléfono</RBForm.Label>
                              <Field name='telefono'>
                                 {({ field }) => <RBForm.Control {...field} type='text' placeholder='Escribe tu teléfono' />}
                              </Field>
                              <ErrorMessage name='telefono' component={ErrorFeedback} />
                           </RBForm.Group>

                           <RBForm.Group controlId='email'>
                              <RBForm.Label>Correo electrónico</RBForm.Label>
                              <Field name='email'>
                                 {({ field }) => <RBForm.Control {...field} type='email' placeholder='nombre@correo.com.mx' />}
                              </Field>
                              <ErrorMessage name='email' component={ErrorFeedback} />
                           </RBForm.Group>

                           <RBForm.Group controlId='password'>
                              <RBForm.Label>Contraseña</RBForm.Label>
                              <Field name='password'>
                                 {({ field }) => (
                                    <RBForm.Control {...field} type='password' placeholder='Escriba su contraseña' />
                                 )}
                              </Field>
                              <ErrorMessage name='password' component={ErrorFeedback} />
                           </RBForm.Group>

                           <RBForm.Group controlId='confirmPassword'>
                              <RBForm.Label>Confirmar contraseña</RBForm.Label>
                              <Field name='confirmPassword'>
                                 {({ field }) => <RBForm.Control {...field} type='password' placeholder='Repita su contraseña' />}
                              </Field>
                              <ErrorMessage name='confirmPassword' component={ErrorFeedback} />
                           </RBForm.Group>

                           <Button
                              variant='primary'
                              className='mt-5 font-weight-bold'
                              type='submit'
                              block
                              disabled={isSubmitting}>
                              {isSubmitting ? (
                                 <FontAwesomeIcon icon={faSpinner} spin size='lg' className='mr-2' />
                              ) : (
                                 'Crear cuenta'
                              )}
                           </Button>

                           <hr className='my-4' />

                           <p className='text-center font-weight-medium'>¿Ya tienes una cuenta?</p>

                           <Link to='/entrar' className='btn btn-info btn-block font-weight-bold'>
                              Iniciar sesión
                           </Link>
                        </Form>
                     )}
                  </Formik>
               </div>
            </Card.Body>
         </Card>
      </div>
   );
};

export default NewAccount;

import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Home from 'components/Shop/Home';
import Producto from 'components/Shop/Producto';
import Productos from 'components/Shop/Productos';
import Contacto from 'components/Shop/Contacto';
import PoliticasEntrega from 'components/Shop/PoliticasEntrega';
import PoliticasDevolucion from 'components/Shop/PoliticasDevolucion';
import Login from 'components/Shop/Auth/Login';
import NewAccount from 'components/Shop/Auth/NewAccount';
import ForgotPassword from 'components/Shop/Auth/ForgotPassword';
import ChangePassword from 'components/Shop/Auth/ChangePassword';
import Carrito from 'components/Shop/Carrito';
import ProcesoPagoStepper from 'components/Shop/ProcesoPago/ProcesoPagoStepper';
import Agradecimiento from 'components/Shop/ProcesoPago/steps/Agradecimiento';
import Perfil from 'components/Shop/Perfil/Perfil';
import DireccionesEnvio from 'components/Shop/Perfil/DireccionesEnvio';
import MetodosPago from 'components/Shop/Perfil/MetodosPago';
import { hasValidToken, getCurrentUser } from 'services/authentication.service';

export interface ShopRoutesProps {
   setUser: React.Dispatch<any>;
   setCartCounter: React.Dispatch<any>;
}

const ShopRoutes: React.FC<ShopRoutesProps> = ({ setUser, setCartCounter }) => {
   let location = useLocation();

   return (
      <Switch>
         <Route exact path='/' component={Home} />
         <Route path={`/productos/:id`} render={() => <Producto setCartCounter={setCartCounter} />} />
         <Route path={`/productos`} component={Productos} />
         <Route path={`/contacto`} component={Contacto} />
         <Route path={`/politicas-entrega`} component={PoliticasEntrega} />
         <Route path={`/politicas-devolucion`} component={PoliticasDevolucion} />
         {/* Auth */}
         <Route path={`/entrar`} render={() => <Login setUser={setUser} />} />
         <Route path={`/registro`} component={NewAccount} />
         <Route path={`/recuperar-cuenta`} component={ForgotPassword} />
         <Route path={`/cambiar-password/:token`} component={ChangePassword} />
         {/* Shopping Cart */}
         <Route path={`/carrito`} render={() => <Carrito setCartCounter={setCartCounter} />} />

         {/* Checar si tiene token valido y es cliente */}
         {hasValidToken() && !getCurrentUser()?.is_admin ? (
            <Switch>
               <Route path={`/proceso-pago`} render={() => <ProcesoPagoStepper setCartCounter={setCartCounter} />} />
               <Route path={`/gracias-por-tu-compra`} component={Agradecimiento} />
               {/* User profile */}
               <Route path={`/perfil/metodos-pago`} component={MetodosPago} />
               <Route path={`/perfil/direcciones`} component={DireccionesEnvio} />
               <Route path={`/perfil`} component={Perfil} />
            </Switch>
         ) : (
            <Redirect
               to={{
                  pathname: '/entrar',
                  ...(location.state ? { state: { prevRoute: location?.state.prevRoute } } : {}),
               }}
            />
         )}

         {/* No routes match 404 */}
         <Route path='*' component={Home} />
      </Switch>
   );
};

export default ShopRoutes;

import React, { useCallback, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { Badge, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { Pagination } from 'typings/tablas';

export interface UsuariosTablaProps {
   usuarios: any[];
   isTableLoading: boolean;
   pagination: Pagination;
   onTableChange: (
      type: any,
      {
         page,
         sizePerPage,
      }: {
         page: any;
         sizePerPage: any;
      }
   ) => void;
   onUsuarioStatusToggle: (usuario: any) => Promise<void>;
}

const UsuariosTabla: React.FC<UsuariosTablaProps> = ({
   usuarios,
   isTableLoading,
   pagination,
   onTableChange,
   onUsuarioStatusToggle,
}) => {
   const estatusBadgeFormatter = useCallback(is_active => {
      if (is_active) {
         return <Badge variant='success'>Activo</Badge>;
      }
      return <Badge variant='danger'>Inactivo</Badge>;
   }, []);

   const optionsFormatter = useCallback(
      (cell, row, rowIndex) => {
         return (
            <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}>Activar/Desactivar</Tooltip>}>
               <Button
                  variant={row.options.is_active ? 'success' : 'danger'}
                  className='mr-2'
                  onClick={() =>
                     onUsuarioStatusToggle({
                        ...row,
                        rowIndex,
                     })
                  }>
                  <FontAwesomeIcon icon={faPowerOff} />
               </Button>
            </OverlayTrigger>
         );
      },
      [onUsuarioStatusToggle]
   );

   const columns = useMemo(() => {
      return [
         {
            dataField: 'usuario_id',
            text: 'ID #',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
         },
         {
            dataField: 'usuarioNombre',
            text: 'Nombre',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
         },
         {
            dataField: 'telefono',
            text: 'Teléfono',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
         },
         {
            dataField: 'email',
            text: 'Correo',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
         },
         {
            dataField: 'is_active',
            text: 'Estatus',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
            formatter: estatusBadgeFormatter,
         },
         {
            dataField: 'options',
            text: 'Opciones',
            isDummyField: true,
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
            formatter: optionsFormatter,
         },
      ];
   }, [estatusBadgeFormatter, optionsFormatter]);

   return (
      <BootstrapTable
         bootstrap4
         striped
         condensed
         classes='usuarios-table'
         keyField='usuario_id'
         data={usuarios}
         columns={columns}
         remote={{
            pagination: true,
            search: true,
         }}
         loading={isTableLoading}
         overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
         hover
         bordered={false}
         noDataIndication='No se encontraron registros.'
         rowClasses={''}
         pagination={paginationFactory({
            page: pagination.page, // Specify the current page.
            totalSize: pagination.totalSize, // Total data size.
            sizePerPage: pagination.sizePerPage, // Specify the size per page.
            withFirstAndLast: false, // hide the going to first and last page button
            alwaysShowAllBtns: true, // always show the next and previous page button
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            hideSizePerPage: true, // hide the size per page dropdown
            hidePageListOnlyOnePage: true,
         })}
         onTableChange={onTableChange}
      />
   );
};

export default UsuariosTabla;

import React from 'react';
import { Modal, Row, Col, Badge, Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import esUS from 'dayjs/locale/es-us';
import { METODOS_ENTREGA_NOMBRES, ORDEN_STATUS } from 'utils/constants';
dayjs.locale(esUS);
dayjs.extend(LocalizedFormat);

export interface OrdenProps {
   orden: any;
   showModal: boolean;
   onHideModal: () => void;
}

const Orden: React.FC<OrdenProps> = ({ orden, showModal, onHideModal }) => {
   const getEstatusBadge = estatus => {
      switch (estatus) {
         case ORDEN_STATUS.PENDIENTE:
            return <Badge variant='secondary'>Pendiente</Badge>;
         case ORDEN_STATUS.PAGADA:
            return <Badge variant='warning'>Pagado</Badge>;
         case ORDEN_STATUS.REEMBOLSADA:
            return <Badge variant='danger'>Reembolsada</Badge>;
         case ORDEN_STATUS.ENTREGADA:
            return <Badge variant='success'>Entregada</Badge>;
         default:
            return <Badge variant='warning'>N/D</Badge>;
      }
   };

   return (
      <Modal show={showModal} centered size='lg' onHide={onHideModal}>
         <Modal.Header closeButton>
            <Modal.Title className='text-primary font-weight-bold'>
               Orden #{orden?.orden_id} - {getEstatusBadge(orden?.estatus)}
            </Modal.Title>
         </Modal.Header>
         <Modal.Body className='px-5 py-4'>
            <Row className='mb-4'>
               <Col md='6'>
                  <label className='font-weight-bold'>Fecha de compra</label>
                  <p>{dayjs(orden?.createdAt).format('MM/DD/YYYY hh:mm A')}</p>
               </Col>
               <Col md='6'>
                  <label className='font-weight-bold'>Fecha de entrega</label>
                  <p>{orden?.createdAt && orden?.estatus === 4 ? dayjs(orden?.updatedAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}</p>
               </Col>
            </Row>

            <hr />
            <p className='font-weight-bold text-primary'>Datos del cliente</p>
            <Row className='mb-4'>
               <Col md='6'>
                  <label className='font-weight-bold'>Cliente</label>
                  <p>{`${orden?.Usuario.nombres} ${orden?.Usuario.apellidos}`}</p>
               </Col>
               <Col md='6'>
                  <label className='font-weight-bold'>Teléfono</label>
                  <p>{orden?.Usuario?.telefono || 'N/A'}</p>
               </Col>
            </Row>

            <Row className='mb-4'>
               <Col md='6'>
                  <label className='font-weight-bold'>Correo</label>
                  <p>{orden?.Usuario?.email || 'N/A'}</p>
               </Col>
               <Col md='6'>
                  <label className='font-weight-bold'>Metodo de entrega</label>
                  <p>{orden?.metodo_entrega ? METODOS_ENTREGA_NOMBRES[orden?.metodo_entrega] : 'N/A'}</p>
               </Col>
            </Row>

            <hr />
            <p className='font-weight-bold text-primary'>{orden?.Direccion ? 'Dirección de envío' : 'Sucursal para recoger'}</p>
            {orden?.Direccion && (
               <Row>
                  <Col md='8'>
                     <label className='font-weight-bold'>Quien recibe</label>
                     <p>{orden?.Direccion.nombres_recibe ?? '-'}</p>
                  </Col>
                  <Col md='4'>
                     <label className='font-weight-bold'>Teléfono</label>
                     <p>{orden?.Direccion.telefono ?? '-'}</p>
                  </Col>
                  <Col md='8'>
                     <label className='font-weight-bold'>Calle</label>
                     <p>{orden?.Direccion.calle}</p>
                  </Col>
                  <Col md='4'>
                     <label className='font-weight-bold'>Número</label>
                     <p>{orden?.Direccion.numero}</p>
                  </Col>
                  <Col md='4'>
                     <label className='font-weight-bold'>Ciudad</label>
                     <p>Nogales</p>
                  </Col>
                  <Col md='4'>
                     <label className='font-weight-bold'>Estado</label>
                     <p>Sonora</p>
                  </Col>
                  <Col md='4'>
                     <label className='font-weight-bold'>Código Postal</label>
                     <p>{orden?.Direccion.codigo_postal ?? '-'}</p>
                  </Col>
                  <Col md='6'>
                     <label className='font-weight-bold'>Referencias</label>
                     <p>{orden?.Direccion.referencias ?? '-'}</p>
                  </Col>
               </Row>
            )}

            {orden?.Sucursale && (
               <Row>
                  <Col md='8'>
                     <label className='font-weight-bold'>Calle</label>
                     <p>{orden?.Sucursale.calle}</p>
                  </Col>
                  <Col md='4'>
                     <label className='font-weight-bold'>Número</label>
                     <p>{orden?.Sucursale.numero}</p>
                  </Col>
                  <Col md='4'>
                     <label className='font-weight-bold'>Teléfono</label>
                     <p>{orden?.Sucursale.telefono ?? '-'}</p>
                  </Col>
                  <Col md='4'>
                     <label className='font-weight-bold'>Ciudad</label>
                     <p>Nogales</p>
                  </Col>
                  <Col md='4'>
                     <label className='font-weight-bold'>Estado</label>
                     <p>Sonora</p>
                  </Col>
                  <Col md='4'>
                     <label className='font-weight-bold'>Código Postal</label>
                     <p>{orden?.Sucursale.codigo_postal ?? '-'}</p>
                  </Col>
               </Row>
            )}

            <hr />
            <p className='font-weight-bold text-primary text-center'>Productos solicitados</p>

            <Table responsive>
               <thead>
                  <tr>
                     <th>Codigo</th>
                     <th>Nombre</th>
                     <th className='text-center'>Cantidad</th>
                  </tr>
               </thead>
               <tbody>
                  {orden?.Orden_productos.map(producto => (
                     <tr>
                        <td title={producto.codigo}>{producto.codigo}</td>
                        <td title={producto.nombre_producto}>{producto.nombre_producto}</td>
                        <td title={producto.cantidad} className='text-center'>
                           {producto.cantidad}
                        </td>
                     </tr>
                  ))}
               </tbody>
            </Table>
         </Modal.Body>
      </Modal>
   );
};

export default Orden;

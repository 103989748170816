import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faSignOutAlt, faShoppingCart, faAddressCard, faSearch } from '@fortawesome/free-solid-svg-icons';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'url-search-params-polyfill';
// Mis Componentes
import http from 'services/http.service';
import { logout, getCurrentUser } from 'services/authentication.service';
import { CATEGORIAS_EXCLUIDAS } from 'utils/constants';
import logo from '../../../assets/images/Logo.png';
import './Navbar.css';
import { Row, Col } from 'react-bootstrap';

export interface ShopNavbarProps {
   user: any;
   setUser: React.Dispatch<any>;
   cartCounter: number;
}

const ShopNavbar: React.FC<ShopNavbarProps> = ({ user, setUser, cartCounter }) => {
   let history = useHistory();
   let location = useLocation();
   const queryParams = new URLSearchParams(location.search);
   const searchTerm: string | undefined = queryParams.get('searchTerm') ?? undefined;
   const [categoria, setCategoria] = useState<string>();
   const [categorias, setCategorias] = useState<any[]>([]);
   const [productos, setProductos] = useState<any[]>([]);
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
   // TODO: Verificar que el carrito guardado en el localstorage pertenece al usuario actual si esta logueado
   let carrito: any[] = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart') as string) : [];
   let counter = cartCounter || carrito.length;

   useEffect(() => {
      const fetchCategorias = async () => {
         try {
            const categoriasData: any = await http.get(`productos/categorias`);
            setCategorias(categoriasData);
         } catch (error) {}
      };

      fetchCategorias();
   }, []);

   const handleCategoriaChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
      setCategoria(input.value);
   };

   const searchProductos = async (query: string) => {
      try {
         setIsLoading(true);
         setIsMenuOpen(true);

         const params = {
            ...(query.trim() && { termino: query }),
            limit: 10,
            page: 1,
         };
         const { results: productos }: any = await http.get('productos', { params });
         setProductos(productos);

         setIsLoading(false);
      } catch (error) {
         setIsLoading(false);
         // if ((error.status && error.status !== 500) || error.type) {
         // toast.error(<ToastMessage type={'error'}>Ocurrió un error al cargar la lista de choferes.</ToastMessage>);
         // }
      }
   };

   const handleSelectedProducto = (selected: any[]) => {
      if (selected.length > 0) {
         history.push(`/productos/${selected[0].id}`);
      }
      setIsMenuOpen(false);
   };

   const handleSearchOnEnter = event => {
      if (event.which === 13 && event.currentTarget.value) {
         history.push(
            `/productos?searchTerm=${encodeURIComponent(event.currentTarget.value)}${
               categoria ? `&categoriaId=${categoria}` : ''
            }`
         );
      } else if (event.which === 13) {
         history.push(`/productos?${categoria ? `&categoriaId=${categoria}` : ''}`);
      }
      setIsMenuOpen(false);
   };

   return (
      <>
         <Navbar bg='primary' variant='dark'>
            <Nav className='mr-auto brand'>
               <img className='logo img-fluid mr-3' src={logo} alt='Logo' />
            </Nav>
            <Nav className='mx-auto mx-md-auto'>
               <Form inline>
                  <Row className='barra-busqueda-container'>
                     <Col md='4' className='pl-0 pr-0 pr-md-1 mb-2 mb-md-0'>
                        <Form.Control
                           className='categorias w-100'
                           name='categorias'
                           as='select'
                           custom
                           onChange={handleCategoriaChange}
                           value={categoria}>
                           <option value={''}>Toda la tienda</option>
                           {categorias
                              .filter(categoria => !CATEGORIAS_EXCLUIDAS.includes(categoria.nombre))
                              .map(categoria => (
                                 <option key={categoria.id} value={categoria.id}>
                                    {categoria.nombre.charAt(0) + categoria.nombre.substring(1).toLowerCase()}
                                 </option>
                              ))}
                        </Form.Control>
                     </Col>
                     <Col md='8' className='px-0'>
                        <div className='barra-busqueda position-relative'>
                           <AsyncTypeahead
                              id={1}
                              className='barra-busqueda-input mr-sm-2 w-100'
                              placeholder='¿Qué estas buscando?'
                              promptText='¿Qué estas buscando?'
                              searchText='Buscando...'
                              emptyLabel='No se encontraron resultados'
                              maxResults={10}
                              minLength={3}
                              delay={500}
                              isLoading={isLoading}
                              onSearch={searchProductos}
                              onChange={handleSelectedProducto}
                              onKeyDown={handleSearchOnEnter}
                              labelKey={'descripcion'}
                              clearButton={false}
                              options={productos}
                              open={isMenuOpen}
                              defaultInputValue={searchTerm}
                           />
                           {/* <FormControl
                        className='barra-busqueda-input mr-sm-2 w-100'
                        name='barra-busqueda'
                        type='text'
                        placeholder='¿Qué estas buscando?'
                        onChange={handleBusquedaChange}
                        value={busqueda}
                     /> */}
                           <FontAwesomeIcon icon={faSearch} size='lg' className='icono-busqueda position-absolute text-primary' />
                        </div>
                     </Col>
                  </Row>
               </Form>
            </Nav>
            <Nav className='ml-auto d-none d-md-flex'>
               {!user ? (
                  <Link
                     to={{
                        pathname: '/entrar',
                        state: { prevRoute: location.pathname },
                     }}
                     className='text-white p-2'>
                     Ingresar
                  </Link>
               ) : (
                  <NavDropdown
                     id='user-profile-dropdown'
                     className='user-profile-dropdown'
                     title={
                        <>
                           <FontAwesomeIcon icon={faUserCircle} size='lg' className='mr-2' />
                           Hola {`${user.nombres}`}
                        </>
                     }
                     alignRight>
                     {getCurrentUser().is_admin ? (
                        <NavDropdown.Item
                           onClick={() => {
                              history.push('/r/ordenes');
                           }}>
                           <FontAwesomeIcon icon={faAddressCard} size='lg' className='mr-2' />
                           Ir al Panel
                        </NavDropdown.Item>
                     ) : (
                        <NavDropdown.Item
                           onClick={() => {
                              history.push('/perfil');
                           }}>
                           <FontAwesomeIcon icon={faAddressCard} size='lg' className='mr-2' />
                           Mi perfil
                        </NavDropdown.Item>
                     )}
                     <NavDropdown.Item
                        onClick={() => {
                           setUser(null);
                           logout();
                           history.replace('/');
                        }}>
                        <FontAwesomeIcon icon={faSignOutAlt} size='lg' className='mr-2' />
                        Cerrar sesión
                     </NavDropdown.Item>
                  </NavDropdown>
               )}

               <div className='p-2'>
                  <Link to='/carrito' className='text-white'>
                     <FontAwesomeIcon icon={faShoppingCart} size='lg' className='mr-2' />
                     <Badge pill variant='light'>
                        {counter < 10 ? counter : '9+'}
                     </Badge>{' '}
                  </Link>
               </div>
            </Nav>
         </Navbar>
         <Navbar collapseOnSelect expand='lg' bg='primary' variant='dark' className=''>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' className='ml-auto' />
            <Navbar.Collapse id='responsive-navbar-nav'>
               <Nav className='justify-content-center'>
                  <Link to='/' className='nav-link'>
                     Inicio
                  </Link>
                  <Link to='/productos' className='nav-link'>
                     Productos
                  </Link>
                  <Link to='/politicas-entrega' className='nav-link'>
                     Entregas
                  </Link>
                  <Link to='/politicas-devolucion' className='nav-link'>
                     Devoluciones
                  </Link>
                  <Link to='/contacto' className='nav-link'>
                     Contacto
                  </Link>
                  <a className='nav-link' target='_blank' rel='noopener noreferrer' href='https://www.medinaferreterias.com/blog'>
                     Blog
                  </a>
               </Nav>
               <Nav className='ml-auto d-flex d-md-none'>
                  {!user ? (
                     <Link to='/entrar' className='text-white px-0 px-lg-2 py-2'>
                        Ingresar
                     </Link>
                  ) : (
                     <NavDropdown
                        id='user-profile-dropdown'
                        className='user-profile-dropdown'
                        title={
                           <>
                              <FontAwesomeIcon icon={faUserCircle} size='lg' className='mr-2' />
                              Hola {`${user.nombres}`}
                           </>
                        }
                        alignRight>
                        <NavDropdown.Item
                           onClick={() => {
                              history.push('/perfil');
                           }}>
                           <FontAwesomeIcon icon={faAddressCard} size='lg' className='mr-2' />
                           Mi perfil
                        </NavDropdown.Item>
                        <NavDropdown.Item
                           onClick={() => {
                              setUser(null);
                              logout();
                              history.replace('/');
                           }}>
                           <FontAwesomeIcon icon={faSignOutAlt} size='lg' className='mr-2' />
                           Cerrar sesión
                        </NavDropdown.Item>
                     </NavDropdown>
                  )}

                  <div className='px-0 px-lg-2 py-2'>
                     <Link to='/carrito' className='text-white'>
                        <FontAwesomeIcon icon={faShoppingCart} size='lg' className='mr-2' />
                        <Badge pill variant='light'>
                           {counter < 10 ? counter : '9+'}
                        </Badge>{' '}
                     </Link>
                  </div>
               </Nav>
            </Navbar.Collapse>
         </Navbar>
      </>
   );
};

export default ShopNavbar;

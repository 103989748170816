import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroller';
import 'url-search-params-polyfill';
// Mis Componentes
import http from 'services/http.service';
import useScrollTop from 'hooks/useScrollTop';
import { CATEGORIAS_EXCLUIDAS, PRODUCTO_IMAGE_PLACEHOLDER, IVA } from 'utils/constants';
import { capitalize, getPrecioNeto } from 'utils';
import './Productos.css';

const productosInitialState = {
   list: [],
   // hasMoreItems: true,
   pagination: {
      // next: null,
      totalSize: 0,
      page: 1,
   },
};
export interface ProductosProps {}

const Productos: React.FC<ProductosProps> = () => {
   let history = useHistory();
   let location = useLocation();
   const queryParams = new URLSearchParams(location.search);
   const search = queryParams.get('searchTerm');
   const categoriaId = queryParams.get('categoriaId');
   const subCategoriaId = queryParams.get('subCategoriaId');
   const subSubCategoriaId = queryParams.get('subSubCategoriaId');
   const [productos, setProductos] = useState<any>({
      ...productosInitialState,
   });
   const [categorias, setCategorias] = useState<any[]>([]);
   const [subCategorias, setSubCategorias] = useState<any[]>([]);
   const [subSubCategorias, setSubSubCategorias] = useState<any[]>([]);
   const [filters, setFilters] = useState<any>({
      categoria: Number(categoriaId),
      subCategoria: Number(subCategoriaId),
      subSubCategoria: Number(subSubCategoriaId),
   });
   const [isLoading, setIsLoading] = useState<boolean>(false);

   useScrollTop();

   useEffect(() => {
      const fetchCategorias = async () => {
         try {
            const categoriasData: any = await http.get(`productos/categorias`);
            setCategorias(categoriasData);
         } catch (error) {
            console.error(error);
         }
      };

      fetchCategorias();
   }, []);

   useEffect(() => {
      fetchProductos();
   }, [search, filters.categoria, filters.subCategoria, filters.subSubCategoria]);

   useEffect(() => {
      if (filters.categoria) {
         fetchSubCategorias(filters.categoria);
      }
   }, [filters.categoria]);

   const fetchProductos = async (page: number = 1) => {
      const { categoria, subCategoria, subSubCategoria } = filters;
      try {
         setIsLoading(true);

         const params = {
            ...(search ? { termino: search } : {}),
            ...(categoria ? { categoria } : {}),
            ...(subCategoria ? { subCategoria } : {}),
            ...(subSubCategoria ? { subSubCategoria } : {}),
            limit: 20,
            page,
         };
         const productosData: any = await http.get(`productos`, { params });
         setUpProductos(productosData);

         setIsLoading(false);
      } catch (error) {
         setIsLoading(false);
      }
   };

   const fetchSubCategorias = async (id: number) => {
      try {
         const subCategoriasData: any = await http.get(`productos/subcategorias`);
         setSubCategorias(subCategoriasData.filter(subCategoria => subCategoria.linea === id));
      } catch (error) {
         console.error(error);
      }
   };

   const fetchSubSubCategorias = async (id: number) => {
      try {
         const subSubCategoriasData: any = await http.get(`productos/subsubcategorias`);
         // console.log(subSubCategoriasData.filter(subSubCategoria => subSubCategoria.sublinea === id));
         setSubSubCategorias(subSubCategoriasData.filter(subSubCategoria => subSubCategoria.sublinea === id));
      } catch (error) {
         console.error(error);
      }
   };

   const setUpProductos = (productosData: any) => {
      if (productosData.results.length > 0) {
         setProductos({
            list: productos.list.concat(productosData.results),
            pagination: {
               ...productosData.pagination,
               totalSize: productosData.count,
            },
         });
      }
   };

   const getProductos = (): any[] => {
      return productos.list.map(producto => (
         <Col key={producto.id} md='3'>
            <Link to={`productos/${producto.id}`}>
               <Card className='product-card'>
                  <div
                     className='product-image'
                     style={{
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundImage: `url(${producto.imagen_url ? producto.imagen_url : PRODUCTO_IMAGE_PLACEHOLDER})`,
                        height: '215px',
                     }}></div>
                  {/* <Card.Img variant='top' src={producto.imagen_url ? producto.imagen_url : PRODUCTO_IMAGE_PLACEHOLDER} /> */}
                  <Card.Body className='pt-3 px-1 pb-1'>
                     <div className='mb-3'>
                        <Card.Text className='text-dark product-name' title={producto.descripcion.toLowerCase()}>
                           {producto.descripcion.toLowerCase()}
                        </Card.Text>
                     </div>
                     <Card.Text className='text-primary product-card-price'>${getPrecioNeto(producto)}</Card.Text>
                  </Card.Body>
               </Card>
            </Link>
         </Col>
      ));
   };

   const getCategorias = (): any[] => {
      return categorias.map(categoria => {
         if (!CATEGORIAS_EXCLUIDAS.includes(categoria.nombre)) {
            return (
               <div key={categoria.id} className='py-2'>
                  <span className='category-link' onClick={() => handleSelectCategoria(categoria.id)}>
                     {capitalize(categoria.nombre)}
                  </span>
               </div>
            );
         }
         return null;
      });
   };

   const getSubCategorias = (): any[] => {
      return subCategorias.map(subCategoria => {
         if (!CATEGORIAS_EXCLUIDAS.includes(subCategoria.nombre)) {
            return (
               <div key={subCategoria.id} className='py-2'>
                  <span className='category-link' onClick={() => handleSelectSubCategoria(subCategoria.id)}>
                     {capitalize(subCategoria.nombre)}
                  </span>
               </div>
            );
         }
         return null;
      });
   };

   const getSubSubCategorias = (): any[] => {
      return subSubCategorias.map(subSubCategoria => {
         if (!CATEGORIAS_EXCLUIDAS.includes(subSubCategoria.nombre)) {
            return (
               <div key={subSubCategoria.id} className='py-2'>
                  <span className='category-link' onClick={() => handleSelectSubSubCategoria(subSubCategoria.id)}>
                     {capitalize(subSubCategoria.nombre)}
                  </span>
               </div>
            );
         }
         return null;
      });
   };

   const getFilters = (): any[] => {
      return Object.entries(filters).map(([key, value], index) => {
         let filtro;
         if (key === 'categoria' && value) {
            filtro = categorias.find(categoriaF => categoriaF.id === value);
         } else if (key === 'subCategoria' && value) {
            filtro = subCategorias.find(subCategoriaF => subCategoriaF.id === value);
         } else if (key === 'subSubCategoria' && value) {
            filtro = subSubCategorias.find(subSubCategoriaF => subSubCategoriaF.id === value);
         }

         if (filtro) {
            return (
               <div key={key} className='py-2'>
                  <span className='category-link' onClick={() => handleDeleteFilter(key)}>
                     <FontAwesomeIcon icon={faTimes} className='mr-2 text-danger' />
                     {capitalize(filtro.nombre)}
                  </span>
               </div>
            );
         } else {
            return null;
         }
      });
   };

   const handleSelectCategoria = (id: number) => {
      setProductos({ ...productosInitialState });
      setFilters({
         ...filters,
         categoria: id,
      });
   };

   const handleSelectSubCategoria = (id: number) => {
      setProductos({ ...productosInitialState });
      setFilters({
         ...filters,
         subCategoria: id,
      });
      fetchSubSubCategorias(id);
   };

   const handleSelectSubSubCategoria = (id: number) => {
      if (id !== filters.subSubCategoria) {
         setProductos({ ...productosInitialState });
         setFilters({
            ...filters,
            subSubCategoria: id,
         });
      }
   };

   const handleDeleteFilter = (tipoFiltro: string) => {
      setProductos({ ...productosInitialState });
      switch (tipoFiltro) {
         case 'categoria': {
            // Clear url query params
            history.replace(`/productos`);
            setFilters({
               categoria: null,
               subCategoria: null,
               subSubCategoria: null,
            });
            break;
         }
         case 'subCategoria':
            setFilters({
               ...filters,
               subCategoria: null,
               subSubCategoria: null,
            });
            break;
         case 'subSubCategoria':
            setFilters({
               ...filters,
               subSubCategoria: null,
            });
            break;
         default:
            break;
      }
   };

   return (
      <div className='container mt-5'>
         {search ? <h5>Resultados de búsqueda "{search ? search.substring(0, 50) : ''}"</h5> : <h5>Resultados</h5>}
         <hr />
         <div className='row mt-4'>
            <div className='col-md-3'>
               <p className='font-weight-bold'>Filtros</p>
               <Card className='categories-card'>
                  <Card.Header className='d-flex justify-content-between align-items-center bg-white font-weight-bold'>
                     <span>Categorias</span>
                     {/* <FontAwesomeIcon icon={faAngleDown} size='lg' className='' /> */}
                  </Card.Header>
                  <Card.Body>
                     {filters.categoria || filters.subCategoria || filters.subSubCategoria ? (
                        <>
                           <div className='filters'>{getFilters()}</div>
                           <hr />
                        </>
                     ) : null}

                     {(filters.subCategoria && getSubSubCategorias()) ||
                        (filters.categoria && getSubCategorias()) ||
                        (!filters.categoria && getCategorias())}
                  </Card.Body>
               </Card>
            </div>
            <div className='col-md-9'>
               <div className='d-flex justify-content-between align-items-center'>
                  <p className='results-counter'>
                     Mostrando {productos.list.length} resultados de {productos.pagination.totalSize}
                  </p>
               </div>
               {productos.list.length > 0 ? (
                  <InfiniteScroll
                     initialLoad={false}
                     pageStart={1}
                     loadMore={fetchProductos}
                     hasMore={productos.list.length < productos.pagination.totalSize && !isLoading}
                     loader={
                        <div key={0} className='py-5 text-center'>
                           <FontAwesomeIcon icon={faSpinner} spin size='lg' className='mr-2' />
                           Cargando...
                        </div>
                     }
                     threshold={1000}>
                     <Row className='productos mb-4 align-content-start flex-wrap'>{getProductos()}</Row>
                  </InfiniteScroll>
               ) : isLoading ? (
                  <div className='productos py-5 text-center'>
                     <FontAwesomeIcon icon={faSpinner} spin size='lg' className='mr-2' />
                     Cargando...
                  </div>
               ) : (
                  <div className='productos py-5 text-center'>No se encontraron resultados</div>
               )}

               <div className='d-flex justify-content-between align-items-center mb-5'>
                  <p className='results-counter'>
                     Mostrando {productos.list.length} resultados de {productos.pagination.totalSize}
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Productos;

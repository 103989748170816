import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import Card from 'react-bootstrap/Card';
import RBAlert from 'react-bootstrap/Alert';
import RBForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
// Mis componentes
import ErrorFeedback from 'components/shared/ErrorFeedback';
import http from 'services/http.service';
import { Alert } from 'typings';

export interface ForgotPasswordProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
   // let history = useHistory();
   const [errorAlert, setErrorAlert] = useState<Alert>({
      isOpen: false,
      message: '',
   });
   const [successAlert, setSuccessAlert] = useState<Alert>({
      isOpen: false,
      message: '',
   });
   const initialValues = {
      email: '',
   };

   const forgotSchema = Yup.object().shape({
      email: Yup.string().email('Escriba un email válido').required('Campo requerido'),
   });

   const handleFormSubmit = async (values, actions) => {
      try {
         await http.post('auth/forgot', {
            email: values.email,
         });
         setSuccessAlert({
            isOpen: true,
            message: 'Por favor, revisa tu bandeja de correos para más instrucciones.',
         });
         actions.setSubmitting(false);
      } catch (error) {
         actions.setSubmitting(false);
         if (error.type === 'Auth/AccountNotFound') {
            setErrorAlert({
               isOpen: true,
               message: 'El correo introducido no existe.',
            });
         }
      }
   };

   const handleCloseAlert = () => {
      if (errorAlert.isOpen) {
         setErrorAlert({
            isOpen: false,
            message: '',
         });
      } else if (successAlert.isOpen) {
         setSuccessAlert({
            isOpen: false,
            message: '',
         });
      }
   };

   return (
      <div className='auth'>
         <Card className='auth-card'>
            <Card.Header className='bg-white text-center text-primary'>
               <FontAwesomeIcon icon={faArrowLeft} size='lg' className='mr-3' />
               Recuperar contraseña
            </Card.Header>
            <Card.Body className='px-5 pb-5'>
               <p className='font-weight-medium'>Enviaremos un mensaje a tu correo para que puedas crear una nueva contraseña.</p>
               {errorAlert.isOpen ? (
                  <RBAlert variant='danger' onClose={handleCloseAlert} dismissible>
                     <p className='mb-0'>{errorAlert.message}</p>
                  </RBAlert>
               ) : null}
               {successAlert.isOpen ? (
                  <RBAlert variant='success'>
                     <p className='mb-0'>{successAlert.message}</p>
                  </RBAlert>
               ) : null}
               <Formik initialValues={initialValues} validationSchema={forgotSchema} onSubmit={handleFormSubmit}>
                  {({ isSubmitting, handleSubmit }: FormikProps<any>) => (
                     <Form>
                        <RBForm.Group controlId='email'>
                           <RBForm.Label>Correo electrónico</RBForm.Label>
                           <Field name='email'>
                              {({ field }) => <RBForm.Control {...field} type='email' placeholder='nombre@correo.com.mx' />}
                           </Field>
                           <ErrorMessage name='email' component={ErrorFeedback} />
                        </RBForm.Group>

                        <Button variant='primary' className='mt-5 font-weight-bold' type='submit' block disabled={isSubmitting}>
                           {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin size='lg' className='mr-2' /> : 'Enviar'}
                        </Button>
                     </Form>
                  )}
               </Formik>
            </Card.Body>
         </Card>
      </div>
   );
};

export default ForgotPassword;

import React from 'react';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
// import RBAlert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import RBForm from 'react-bootstrap/Form';
import ErrorFeedback from 'components/shared/ErrorFeedback';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUpload } from '@fortawesome/free-solid-svg-icons';
// Mis Componentes
import http from 'services/http.service';
// import apiErrorHandler from 'services/apiErrorHandler.service';
// Mis Types
import { SUPPORTED_FORMATS, MAX_FILE_SIZE } from 'utils/constants';
import apiErrorHandler from 'services/apiErrorHandler.service';

export interface BannerProps {
   banner: any;
   showModal: boolean;
   onHideModal: () => void;
   reload: () => Promise<void>;
}

const Banner: React.FC<BannerProps> = ({ banner, showModal, onHideModal, reload }) => {
   const bannerSchema = Yup.object().shape({
      ...(banner?.banner_id
         ? {
              banner: Yup.mixed()
                 .notRequired()
                 .test('fileFormat', 'Formato no permitido. Solo jpg, jpeg, png', value =>
                    !value ? true : value && SUPPORTED_FORMATS.includes(value.type)
                 )
                 .test('fileDimensions', 'Archivo no corresponde con las medidas 1000px x 341px', value =>
                    !value || typeof value === 'string' ? true : value && value.width === 1000 && value.height === 341
                 )
                 .test('fileSize', 'Archivo demasiado grande. Máximo 150KB', value =>
                    !value ? true : value && value.size <= MAX_FILE_SIZE
                 ),
           }
         : {
              banner: Yup.mixed()
                 .required('Banner requerido')
                 .test(
                    'fileFormat',
                    'Formato no permitido. Solo jpg, jpeg, png',
                    value => value && SUPPORTED_FORMATS.includes(value.type)
                 )
                 .test('fileDimensions', 'Archivo no corresponde con las medidas 1000px x 341px', value =>
                    !value || typeof value === 'string' ? true : value && value.width === 1000 && value.height === 341
                 )
                 .test('fileSize', 'Archivo demasiado grande. Máximo 150KB', value => value && value.size <= MAX_FILE_SIZE),
           }),
      url: Yup.string().notRequired('Campo requerido').url('Ingresa una URL válida').trim(),
   });

   const handleFormSubmit = async (values, actions) => {
      try {
         banner?.banner_id ? await updateBanner(values) : await createBanner(values);
      } catch (error) {
         apiErrorHandler('Banner', error);
      }
   };

   const createBanner = async (values: any) => {
      const body = setFormData(values);
      await http.post('banners', body);

      onHideModal();

      Swal.fire({
         title: 'Banner guardado!',
         icon: 'success',
         confirmButtonText: 'Aceptar',
         allowOutsideClick: false,
         customClass: {
            confirmButton: 'btn btn-primary px-5',
         },
         buttonsStyling: false,
      });

      reload();
   };

   const updateBanner = async (values: any) => {
      const body = setFormData(values);
      await http.put(`banners/${values.banner_id}`, body);

      onHideModal();

      Swal.fire({
         title: 'Datos actualizados!',
         icon: 'success',
         confirmButtonText: 'Aceptar',
         allowOutsideClick: false,
         customClass: {
            confirmButton: 'btn btn-primary px-5',
         },
         buttonsStyling: false,
      });

      reload();
   };

   const setFormData = values => {
      const body = new FormData();
      for (const key of Object.keys(values)) {
         if (values[key]) {
            body.append(key, values[key]);
         }
      }
      return body;
   };

   const handleFileInputChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>, setFieldValue) => {
      if (input.files) {
         const file = input.files[0];
         if (SUPPORTED_FORMATS.includes(file.type)) {
            let img = new Image();
            img.onload = function ({ target }) {
               if (target) {
                  let imageTarget: any = target;
                  file['width'] = imageTarget.naturalWidth;
                  file['height'] = imageTarget.naturalHeight;
                  setFieldValue(`banner`, file);
               }
            };
            img.src = URL.createObjectURL(file);
         } else {
            setFieldValue(`banner`, file);
         }
      }
   };

   return (
      <Modal show={showModal} centered size='lg' onHide={onHideModal}>
         <Modal.Header closeButton>
            <Modal.Title className='text-primary font-weight-bold'>Agregar banner</Modal.Title>
         </Modal.Header>
         <Modal.Body className='px-5 py-4'>
            <Formik
               initialValues={banner}
               validationSchema={bannerSchema}
               onSubmit={handleFormSubmit}
               render={({ values, setFieldValue, isSubmitting }: FormikProps<any>) => (
                  <Form className='banner-form text-left'>
                     <RBForm.Group controlId='url'>
                        <RBForm.Label>URL de redireccionamiento</RBForm.Label>
                        <Field name='url'>{({ field }) => <RBForm.Control type='text' placeholder='URL' {...field} />}</Field>
                        <ErrorMessage name='url' component={ErrorFeedback} />
                     </RBForm.Group>

                     <Row>
                        <Col>
                           <p className='form-label mb-1'>Imagen</p>
                           <p className='text-muted'>Imagen debe ser de 1000px x 341px</p>
                           <label className='btn btn-primary cursor-pointer font-weight-bold px-4' htmlFor={`banner-${1}`}>
                              <FontAwesomeIcon icon={faUpload} className='mr-2' /> Elegir archivo
                           </label>
                           <input
                              id={`banner-${1}`}
                              name={`banner`}
                              className='d-none'
                              type='file'
                              accept='.png,.jpeg,.jpg'
                              onChange={e => handleFileInputChange(e, setFieldValue)}
                           />
                           <span className='font-weight-bold text-muted ml-3'>
                              {values?.banner ? values.banner.name : values?.bannerSrc ? values.bannerSrc.split('/')[4] : ''}
                           </span>
                           <ErrorMessage name={`banner`} component={ErrorFeedback} />
                        </Col>
                     </Row>
                     <div className='text-center'>
                        <Button
                           variant='success'
                           type='submit'
                           className='btn-wide font-weight-bold mt-4'
                           disabled={isSubmitting}>
                           {isSubmitting ? (
                              <FontAwesomeIcon icon={faSpinner} pulse size='lg' />
                           ) : banner?.banner_id ? (
                              'Guardar cambios'
                           ) : (
                              'Guardar'
                           )}
                        </Button>
                     </div>
                  </Form>
               )}
            />
         </Modal.Body>
      </Modal>
   );
};

export default Banner;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
// Mis componentes
import { getCurrentUser } from 'services/authentication.service';
import ShopNavbar from './Navbar/Navbar';
import ShopRoutes from 'routes/shop.routes';
import Footer from './Footer/Footer';
import './Shop.css';

export interface ShopProps {}

const Shop: React.FC<ShopProps> = () => {
   const [user, setUser] = useState<any>();
   const [cartCounter, setCartCounter] = useState<number>(0);

   useEffect(() => {
      const user = getCurrentUser();
      setUser(user);
   }, []);

   return (
      <>
         <ShopNavbar user={user} setUser={setUser} cartCounter={cartCounter} />
         <ShopRoutes setUser={setUser} setCartCounter={setCartCounter} />
         <a id='whatsapp' href='https://wa.link/fhg73p' target='_blank' rel='noopener noreferrer' title='Soporte'>
            <FontAwesomeIcon icon={faWhatsapp} size='lg' />
         </a>
         <Footer />
      </>
   );
};

export default Shop;

import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import RBAlert from 'react-bootstrap/Alert';
import RBForm from 'react-bootstrap/Form';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
// Mis Componentes
import ErrorFeedback from 'components/shared/ErrorFeedback';
import http from 'services/http.service';
import { generateYears, getBrandIcon } from 'utils';
import '../ProcesoPagoStepper.css';
declare global {
	interface Window {
		Conekta: any;
	}
}

interface ElegirTarjetaProps {
	orden: any;
	setOrden: React.Dispatch<any>;
	onBackBtnClick: () => void;
	onNextBtnClick: () => void;
}

const ElegirTarjeta: React.FC<ElegirTarjetaProps> = ({ orden, setOrden, onBackBtnClick, onNextBtnClick }) => {
	const [tarjetas, setTarjetas] = useState<any[]>([]);
	// const [loading, setLoading] = useState<boolean>(false);
	const [showModal, setShowModal] = useState(false);
	const [genericAlert, setGenericAlert] = useState<any>({
		isOpen: false,
		variant: '',
		message: '',
	});
	const initialValues = {
		nombre: '',
		cardNumber: '',
		mes: '',
		ano: '',
		cvv: '',
	};
	const agregarTarjetaSchema = Yup.object().shape({
		nombre: Yup.string().min(6, 'Mínimo 6 caracteres').max(100, 'Máximo 100 caracteres').trim().required('Campo requerido'),
		cardNumber: Yup.string()
			.matches(
				/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
				'Ingrese un número de tarjeta válido'
			)
			.required('Campo requerido'),
		mes: Yup.string().required('Campo requerido'),
		ano: Yup.string().required('Campo requerido'),
		cvv: Yup.string()
			.min(3, 'Mínimo 3 digitos')
			.max(4, 'Máximo 4 digitos')
			.matches(/^[\d]{3,4}$/, 'Solo números')
			.required('Campo requerido'),
	});

	useEffect(() => {
		const fetchTarjetas = async () => {
			try {
				const tarjetasData: any[] = await http.get(`/payment_sources`);
				setTarjetas(tarjetasData.reverse());
			} catch (error) {
				console.error(error);
			}
		};

		fetchTarjetas();
	}, []);

	const getTarjetas = (): JSX.Element[] => {
		let tarjetasMapped = tarjetas.map(tarjeta => (
			<Col key={tarjeta.id} md='3' className='mb-4'>
				<Card
					className={`card-shadow cursor-pointer ${orden.tarjeta?.id === tarjeta.id ? 'active-address' : ''}`}
					onClick={() =>
						setOrden({
							...orden,
							tarjeta: tarjeta,
						})
					}>
					<Card.Body className='py-2 px-0 text-center'>
						<div>
							<img src={getBrandIcon(tarjeta?.brand)} className='brand-icon mr-2' alt='marca' />
							&#9679;&#9679;&#9679;&#9679; {tarjeta?.last4}
						</div>
					</Card.Body>
				</Card>
			</Col>
		));

		tarjetasMapped.push(
			<Col key={0} md='3'>
				<Card className='card-shadow cursor-pointer' onClick={() => setShowModal(true)}>
					<Card.Body className='py-3 px-0 text-center text-primary'>
						<div>
							<FontAwesomeIcon icon={faCreditCard} size='lg' className='mr-2' /> Usar otra tarjeta
						</div>
					</Card.Body>
				</Card>
			</Col>
		);

		return tarjetasMapped;
	};

	const handleFormSubmit = async (values, actions) => {
		const tokenParams = {
			card: {
				number: values.cardNumber,
				name: values.nombre,
				exp_year: values.ano,
				exp_month: values.mes,
				cvc: values.cvv,
			},
		};

		createCardToken(tokenParams, actions);
	};

	const createCardToken = (tokenParams, actions) => {
		debugger;
		window?.Conekta?.Token.create(
			tokenParams,
			async cardToken => {
				try {
					Swal.fire('Procesando...', 'Espere un momento...', 'info');
					Swal.showLoading();

					const body = {
						token_id: cardToken.id,
					};
					const newTarjeta = await http.post(`payment_sources`, body);

					setTarjetas(tarjetas.concat(newTarjeta));

					setOrden({
						...orden,
						tarjeta: newTarjeta,
					});
					Swal.close();
					handleCloseModal();
				} catch (error) {
					Swal.close();
					actions.setSubmitting(false);
					// console.error(error);
					setGenericAlert({
						isOpen: true,
						variant: 'danger',
						message: error.message,
					});
				}
			},
			error => {
				// console.error(error);
				setGenericAlert({
					isOpen: true,
					variant: 'danger',
					message: error.message_to_purchaser || 'Ocurrio un error.',
				});
			}
		);
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setGenericAlert({
			isOpen: false,
			variant: '',
			message: '',
		});
	};

	const getYearOptions = (): JSX.Element[] => {
		let years: JSX.Element[] = [
			<option value={''} disabled>
				Año
			</option>,
		];
		return years.concat(generateYears().map(year => <option key={year}>{year}</option>));
	};

	return (
		<>
			<p className='font-weight-bold'>Elige tu tarjeta</p>
			{tarjetas.length > 0 ? (
				<Row>{getTarjetas()}</Row>
			) : (
				<Row>
					<Col md='3'>
						<Card className='card-shadow cursor-pointer' onClick={() => setShowModal(true)}>
							<Card.Body className='py-2 px-0 text-center text-primary'>
								<div>
									<FontAwesomeIcon icon={faCreditCard} size='lg' className='mr-2' /> Usar otra tarjeta
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			)}

			<Row className='mt-5'>
				<Col md='6' className='text-center d-md-flex justify-content-md-end mb-4 mb-md-0'>
					<Button variant='light' className='font-weight-bold px-5 btn-stepper text-primary' onClick={onBackBtnClick}>
						Atras
					</Button>
				</Col>
				<Col md='6' className='text-center text-md-left'>
					<Button variant='primary' className='font-weight-bold px-5 btn-stepper' onClick={onNextBtnClick}>
						Siguiente
					</Button>
				</Col>
			</Row>

			<Modal show={showModal} centered onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title className='text-primary font-weight-bold'>Nueva tarjeta</Modal.Title>
				</Modal.Header>
				<Modal.Body className='px-5 py-4'>
					{genericAlert.isOpen ? (
						<RBAlert className='text-left' variant={genericAlert.variant}>
							{genericAlert.message}
						</RBAlert>
					) : null}
					<Formik
						initialValues={initialValues}
						validationSchema={agregarTarjetaSchema}
						onSubmit={handleFormSubmit}
						render={({ isSubmitting }: FormikProps<any>) => (
							<Form className='agregar-tarjeta-form'>
								<RBForm.Group controlId='nombre'>
									<RBForm.Label>Nombre del tarjetahabiente</RBForm.Label>
									<Field name='nombre'>
										{({ field }) => (
											<RBForm.Control {...field} type='nombre' placeholder='Nombre del tarjetahabiente' />
										)}
									</Field>
									<ErrorMessage name='nombre' component={ErrorFeedback} />
								</RBForm.Group>

								<RBForm.Group controlId='cardNumber'>
									<RBForm.Label>Número de tarjeta</RBForm.Label>
									<Field name='cardNumber'>
										{({ field }) => <RBForm.Control {...field} type='cardNumber' placeholder='xxxx-xxxx-xxxx-xxxx' />}
									</Field>
									<ErrorMessage name='cardNumber' component={ErrorFeedback} />
								</RBForm.Group>

								<label className='form-label font-weight-medium d-block'>Fecha de expiración</label>
								<Row>
									<Col md='6'>
										<RBForm.Group controlId='mes'>
											<Field name='mes'>
												{({ field }) => (
													<RBForm.Control {...field} as='select' type='mes'>
														<option value={''} disabled>
															Mes
														</option>
														<option>01</option>
														<option>02</option>
														<option>03</option>
														<option>04</option>
														<option>05</option>
														<option>06</option>
														<option>07</option>
														<option>08</option>
														<option>09</option>
														<option>10</option>
														<option>11</option>
														<option>12</option>
													</RBForm.Control>
												)}
											</Field>
											<ErrorMessage name='mes' component={ErrorFeedback} />
										</RBForm.Group>
									</Col>
									<Col md='6'>
										<RBForm.Group controlId='ano'>
											<Field name='ano'>
												{({ field }) => (
													<RBForm.Control {...field} as='select' type='ano'>
														{getYearOptions()}
													</RBForm.Control>
												)}
											</Field>
											<ErrorMessage name='ano' component={ErrorFeedback} />
										</RBForm.Group>
									</Col>
								</Row>

								<RBForm.Group controlId='cvv'>
									<RBForm.Label>CVV</RBForm.Label>
									<Field name='cvv'>{({ field }) => <RBForm.Control {...field} type='cvv' placeholder='XXX' />}</Field>
									<ErrorMessage name='cvv' component={ErrorFeedback} />
								</RBForm.Group>

								{(genericAlert.isOpen && genericAlert.variant === 'danger') ||
									(!genericAlert.isOpen && !genericAlert.variant) ? (
									<div className='text-center'>
										<Button
											variant='primary'
											type='submit'
											className='btn-wide font-weight-bold mt-5 px-5'
											disabled={isSubmitting}>
											{isSubmitting ? <FontAwesomeIcon icon={faSpinner} pulse size='lg' /> : 'Guardar tarjeta'}
										</Button>
									</div>
								) : null}
							</Form>
						)}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ElegirTarjeta;

const jwt = require('jsonwebtoken');
// import ability from 'services/authorization.service';

export function storeToken(token) {
   localStorage.setItem('token', token);
}

export async function setPublicKey() {
   try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}auth/publicKey`, {
         headers: new Headers({
            'Content-Type': 'plain/text',
         }),
         method: 'GET',
      });
      if (!response.ok) {
         logout();
         return null;
      }
      const responseJson = await response.text();
      sessionStorage.setItem('publicKey', responseJson);
   } catch (error) {
      console.error(error);
   }
}

export function getToken() {
   return localStorage.getItem('token');
}

export function isTokenExpired() {
   try {
      const token: any = getToken();
      if (token) {
         jwt.verify(token, sessionStorage.getItem('publicKey'), { expiresIn: '1day', algorithm: ['RS256'] });
      }
      return false;
   } catch (error) {
      if (error.name === 'TokenExpiredError') {
         return true;
      }
      return true;
   }
}

export function hasValidToken() {
   try {
      const token: any = getToken();
      if (token) {
         jwt.verify(token, sessionStorage.getItem('publicKey'), { expiresIn: '1day', algorithm: ['RS256'] });
         return true;
      }
      return false;
   } catch (error) {
      return true;
   }
}

export async function refreshToken() {
   try {
      const token = getToken();
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}auth/refresh`, {
         headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         }),
         method: 'GET',
      });
      if (!response.ok) {
         logout();
         return null;
      }
      const newToken = await response.json();
      storeToken(newToken.token);
   } catch (error) {
      console.error(error);
   }
}

export function getCurrentUser() {
   try {
      const token = getToken();
      return jwt.decode(token);
   } catch (ex) {
      return null;
   }
}

export function logout() {
   localStorage.removeItem('token');
   // ability.update([]); // Quita todas las reglas de los permisos
}

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import DashboardGuard from 'guards/Dashboard.guard';
import Shop from 'components/Shop/Shop';
// Dashboard components
import Dashboard from 'components/Dashboard/Dashboard';

const AppRoutes: React.FC = () => {
   return (
      <Router>
         <Switch>
            <DashboardGuard path='/r' component={Dashboard} />
            <Route path='/' component={Shop} />
         </Switch>
      </Router>
   );
};

export default AppRoutes;

import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import UsuariosTabla from './UsuariosTabla';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
// Mis Componentes
import http from 'services/http.service';
import useDebounce from 'hooks/useDebounce';
import { Pagination } from 'typings/tablas';

export interface UsuariosProps {}

const Usuarios: React.FC<UsuariosProps> = () => {
   const [usuarios, setUsuarios] = useState<any[]>([]);
   const [search, setSearch] = useState<string>('');
   const debouncedSearch = useDebounce(search.trim(), 500);
   const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
   const [pagination, setPagination] = useState<Pagination>({
      page: 1,
      totalSize: 10,
      sizePerPage: 10,
   });
   const [showModal, setShowModal] = useState(false);

   // Obtener la lista de grupos
   useEffect(() => {
      fetchUsuarios();
      // eslint-disable-next-line
   }, [debouncedSearch, pagination.page]);

   const fetchUsuarios = async () => {
      const { page, sizePerPage: limit } = pagination;
      try {
         setIsTableLoading(true);

         const params = {
            ...(debouncedSearch && { search: debouncedSearch }),
            limit,
            page,
         };
         const { rows: UsuariosList, count: totalSize }: any = await http.get('users', { params });
         setUsuarios(mapUsuariosToViewModel(UsuariosList));
         setPagination({ ...pagination, totalSize });

         setIsTableLoading(false);
      } catch (error) {
         setIsTableLoading(false);
         //    if ((error.status && error.status !== 500) || error.type) {
         //       toast.error(<ToastMessage type={'error'}>Ocurrió un error al cargar la lista de choferes.</ToastMessage>);
         //    }
      }
   };

   const mapUsuariosToViewModel = (usuarios: any[]) => {
      return usuarios.map(usuario => {
         return {
            usuario_id: usuario.usuario_id,
            usuarioNombre: `${usuario.nombres} ${usuario.apellidos}`,
            telefono: usuario.telefono,
            email: usuario.email,
            is_active: usuario.is_active,
            options: { is_active: usuario.is_active },
         };
      });
   };

   const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(input.value);
   };

   const handleRefresh = () => {
      fetchUsuarios();
   };

   const handleTableChange = (type, { page, sizePerPage }) => {
      setPagination({
         ...pagination,
         page,
      });
   };

   const handleUsuarioEstatusToggle = async (usuario: any) => {
      const result = await Swal.fire({
         title: `¿Estás seguro que deseas ${usuario.is_active ? 'desactivar' : 'activar'} este usuario? ${usuario.is_active}`,
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Aceptar',
         cancelButtonText: 'Cancelar',
         customClass: {
            confirmButton: 'btn btn-primary px-5',
            cancelButton: 'btn btn-outline-secondary px-5 mr-2',
            content: 'confirmation-modal-text',
         },
         reverseButtons: true,
         buttonsStyling: false,
      });
      if (result.value) {
         try {
            await http.put(`users/${usuario.usuario_id}/estatus`, { estatus: !usuario.is_active });

            // Actualizar UI
            const newUsuarios = usuarios.map(_usuario => {
               if (_usuario.usuario_id === usuario.usuario_id) {
                  return {
                     ..._usuario,
                     is_active: !_usuario.is_active,
                     options: {
                        is_active: !_usuario.is_active,
                     },
                  };
               } else {
                  return _usuario;
               }
            });
            setUsuarios(newUsuarios);
         } catch (error) {
            // toast.error(<ToastMessage type={'error'}>Ha ocurrido un error, intente de nuevo.</ToastMessage>);
         }
      }
   };

   const handleHideModal = () => {
      setShowModal(false);
   };

   return (
      <section id='usuarios'>
         <h3 className='font-weight-bold'>Usuarios</h3>
         <Card>
            <Card.Body>
               <div className='row'>
                  <div className='col-md-9'>
                     <InputGroup className='mb-3'>
                        <InputGroup.Prepend>
                           <InputGroup.Text id='basic-addon1'>
                              <FontAwesomeIcon icon={faSearch} size='lg' />
                           </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl placeholder='Buscar por ID o Nombre' onChange={handleSearchChange} />
                        <Button variant='primary' className='ml-3' onClick={handleRefresh}>
                           <FontAwesomeIcon icon={faSyncAlt} size='lg' />
                        </Button>
                     </InputGroup>
                  </div>
               </div>
               <UsuariosTabla
                  usuarios={usuarios}
                  isTableLoading={isTableLoading}
                  pagination={pagination}
                  onTableChange={handleTableChange}
                  onUsuarioStatusToggle={handleUsuarioEstatusToggle}
               />
            </Card.Body>
         </Card>
      </section>
   );
};

export default Usuarios;

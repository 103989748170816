import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import OrdenesTabla from './OrdenesTabla';
import { InputGroup, FormControl, Button, Badge, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
// Mis Componentes
import http from 'services/http.service';
import useDebounce from 'hooks/useDebounce';
import { Pagination } from 'typings/tablas';
import Orden from './Orden';
import { METODOS_ENTREGA_NOMBRES, ORDEN_STATUS, ORDEN_STATUS_NOMBRE } from 'utils/constants';

export interface OrdenesProps {}

const Ordenes: React.FC<OrdenesProps> = () => {
   const [ordenes, setOrdenes] = useState<any[]>([]);
   const [orden, setOrden] = useState<any>();
   const [search, setSearch] = useState<string>('');
   const debouncedSearch = useDebounce(search.trim(), 500);
   const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
   const [pagination, setPagination] = useState<Pagination>({
      page: 1,
      totalSize: 10,
      sizePerPage: 10,
   });
   const [showModal, setShowModal] = useState(false);

   // Obtener la lista de grupos
   useEffect(() => {
      fetchOrdenes();
      // eslint-disable-next-line
   }, [debouncedSearch, pagination.page]);

   const fetchOrdenes = async () => {
      const { page, sizePerPage: limit } = pagination;
      try {
         setIsTableLoading(true);

         const params = {
            ...(debouncedSearch && { search: debouncedSearch }),
            limit,
            page,
         };
         const { rows: OrdenesList, count: totalSize }: any = await http.get('ordenes', { params });
         setOrdenes(mapOrdenesToViewModel(OrdenesList));
         setPagination({ ...pagination, totalSize });

         setIsTableLoading(false);
      } catch (error) {
         setIsTableLoading(false);
         //    if ((error.status && error.status !== 500) || error.type) {
         //       toast.error(<ToastMessage type={'error'}>Ocurrió un error al cargar la lista de choferes.</ToastMessage>);
         //    }
      }
   };

   const mapOrdenesToViewModel = (ordenes: any[]) => {
      return ordenes.map(orden => {
         return {
            orden_id: orden.orden_id,
            cliente: `${orden.Usuario.nombres} ${orden.Usuario.apellidos}`,
            metodoEntrega: orden.metodo_entrega ? METODOS_ENTREGA_NOMBRES[orden.metodo_entrega] : 'N/A',
            metodoPago: orden.MetodosPago.nombre,
            estatus: ORDEN_STATUS_NOMBRE[orden.estatus],
            fechaCompra: dayjs(orden.createdAt).format('L'),
            options: orden,
         };
      });
   };

   const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(input.value);
   };

   const handleRefresh = () => {
      fetchOrdenes();
   };

   // const handleCleanFilters = () => {
   //    setSearch('');
   //    setPagination({
   //       ...pagination,
   //       sizePerPage: 10,
   //    });
   // };

   const handleTableChange = (type, { page, sizePerPage }) => {
      setPagination({
         ...pagination,
         page,
      });
   };

   const handleMarcarEntregada = async (id: number) => {
      const result = await Swal.fire({
         title: '¿Estas seguro que quieres marcar la orden como entregada?',
         text: 'Esta acción no puede ser revertida.',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Aceptar',
         cancelButtonText: 'Cancelar',
         customClass: {
            confirmButton: 'btn btn-primary px-5',
            cancelButton: 'btn btn-outline-secondary px-5 mr-2',
            content: 'confirmation-modal-text',
         },
         reverseButtons: true,
         buttonsStyling: false,
      });
      if (result.value) {
         try {
            await http.put(`ordenes/${id}/estatus`, { estatus: 4 });

            const mappedOrdenes = ordenes.map(orden => {
               if (orden.orden_id === id) {
                  return {
                     ...orden,
                     estatus: ORDEN_STATUS_NOMBRE[ORDEN_STATUS.ENTREGADA],
                     options: {
                        ...orden.options,
                        estatus: ORDEN_STATUS.ENTREGADA,
                     },
                  };
               } else {
                  return orden;
               }
            });
            setOrdenes(mappedOrdenes);

            // toast.success(<ToastMessage type={'success'}>La acción fue realizada con éxito.</ToastMessage>);
         } catch (error) {
            // toast.error(<ToastMessage type={'error'}>Ha ocurrido un error, intente de nuevo.</ToastMessage>);
         }
      }
   };

   const handleReembolsar = async (id: number, conekta_orden_id: string) => {
      const result = await Swal.fire({
         title: '¿Estas seguro que quieres reembolsar esta orden?',
         text: 'Esta acción no puede ser revertida.',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Aceptar',
         cancelButtonText: 'Cancelar',
         customClass: {
            confirmButton: 'btn btn-primary px-5',
            cancelButton: 'btn btn-outline-secondary px-5 mr-2',
            content: 'confirmation-modal-text',
         },
         reverseButtons: true,
         buttonsStyling: false,
      });
      if (result.value) {
         try {
            await http.post(`ordenes/${id}/reembolsar`, { conekta_orden_id });

            const mappedOrdenes = ordenes.map(orden => {
               if (orden.orden_id === id) {
                  return {
                     ...orden,
                     estatus: ORDEN_STATUS_NOMBRE[ORDEN_STATUS.REEMBOLSADA],
                     options: {
                        ...orden.options,
                        estatus: ORDEN_STATUS.REEMBOLSADA,
                     },
                  };
               } else {
                  return orden;
               }
            });
            setOrdenes(mappedOrdenes);
            // toast.success(<ToastMessage type={'success'}>La acción fue realizada con éxito.</ToastMessage>);
         } catch (error) {
            // toast.error(<ToastMessage type={'error'}>Ha ocurrido un error, intente de nuevo.</ToastMessage>);
         }
      }
   };

   const showOrden = orden => {
      setShowModal(true);
      setOrden(orden);
   };

   const handleHideModal = () => {
      setShowModal(false);
   };

   return (
      <section id='ordenes'>
         <h3 className='font-weight-bold'>Ordenes</h3>
         <Card>
            <Card.Body>
               <div className='row'>
                  <div className='col-md-9'>
                     <InputGroup className='mb-3'>
                        <InputGroup.Prepend>
                           <InputGroup.Text id='basic-addon1'>
                              <FontAwesomeIcon icon={faSearch} size='lg' />
                           </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl placeholder='Buscar por ID o Cliente' onChange={handleSearchChange} />
                        {/* <Button variant='primary' className='mx-3' onClick={handleCleanFilters}>
                           <FontAwesomeIcon icon={faEraser} size='lg' />
                        </Button> */}
                        <Button variant='primary' className='ml-3' onClick={handleRefresh}>
                           <FontAwesomeIcon icon={faSyncAlt} size='lg' />
                        </Button>
                     </InputGroup>
                  </div>
                  {/* <div className='col-md-6'>
               <Form.Group as={Row} controlId='formHorizontalEmail'>
                  <Form.Label column md={3} className='pr-md-0'>
                     Número de registros
                  </Form.Label>
                  <div className='col-md-2 pl-md-0'>
                     <Form.Control as='select'>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                     </Form.Control>
                  </div>
               </Form.Group>
            </div> */}
               </div>
               <OrdenesTabla
                  ordenes={ordenes}
                  showOrden={showOrden}
                  isTableLoading={isTableLoading}
                  pagination={pagination}
                  onTableChange={handleTableChange}
                  onMarcarEntregada={handleMarcarEntregada}
                  onReembolsar={handleReembolsar}
               />
               <Orden orden={orden} showModal={showModal} onHideModal={handleHideModal} />

               <Table className='mt-5' size='sm'>
                  <thead>
                     <tr>
                        <th>Estatus</th>
                        <th>Descripción</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>
                           <Badge variant='secondary'>Pendiente</Badge>
                        </td>
                        <td>El pago de la orden aun esta pendiente</td>
                     </tr>
                     <tr>
                        <td>
                           <Badge variant='warning'>Pagada</Badge>
                        </td>
                        <td>La orden ha sido pagada</td>
                     </tr>
                     <tr>
                        <td>
                           <Badge variant='success'>Entregada</Badge>
                        </td>
                        <td>La orden ha sido entregada al cliente, ya sea a domicilio o en sucursal</td>
                     </tr>
                     <tr>
                        <td>
                           <Badge variant='danger'>Reembolsada</Badge>
                        </td>
                        <td>La orden ha sido reembolsada al cliente (Opción solo disponible en pagos con tarjeta)</td>
                     </tr>
                  </tbody>
               </Table>
            </Card.Body>
         </Card>
      </section>
   );
};

export default Ordenes;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import RBAlert from 'react-bootstrap/Alert';
import RBForm from 'react-bootstrap/Form';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowLeft, faTrashAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
// Mis Componentes
import ErrorFeedback from 'components/shared/ErrorFeedback';
import http from 'services/http.service';
import { generateYears, getBrandIcon } from 'utils';
import './Perfil.css';
declare global {
	interface Window {
		Conekta: any;
	}
}

export interface MetodosPagoProps { }

const MetodosPago: React.FC<MetodosPagoProps> = () => {
	const [metodosPago, setMetodosPago] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [showModal, setShowModal] = useState(false);
	const [genericAlert, setGenericAlert] = useState<any>({
		isOpen: false,
		variant: '',
		message: '',
	});
	const initialValues = {
		nombre: '',
		cardNumber: '',
		mes: '',
		ano: '',
		cvv: '',
	};
	const agregarMetodoPagoSchema = Yup.object().shape({
		nombre: Yup.string().min(6, 'Mínimo 6 caracteres').max(100, 'Máximo 100 caracteres').trim().required('Campo requerido'),
		cardNumber: Yup.string()
			.matches(
				/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
				'Ingrese un número de tarjeta válido'
			)
			.required('Campo requerido'),
		mes: Yup.string().required('Campo requerido'),
		ano: Yup.string().required('Campo requerido'),
		cvv: Yup.string()
			.min(3, 'Mínimo 3 digitos')
			.max(4, 'Máximo 4 digitos')
			.matches(/^[\d]{3,4}$/, 'Solo números')
			.required('Campo requerido'),
	});

	useEffect(() => {
		const fetchMetodosPago = async () => {
			try {
				setLoading(true);

				const metodosPagoData: any[] = await http.get(`/payment_sources`);
				setMetodosPago(metodosPagoData.reverse());

				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.error(error);
			}
		};

		fetchMetodosPago();
	}, []);

	const getMetodosPago = (): JSX.Element[] => {
		return metodosPago.map((metodo, index) => (
			<Col key={metodo.id} md='4' className='mb-4'>
				<Card className='card-shadow'>
					<Card.Body className='py-3'>
						<p className='font-weight-bold text-primary'>Tarjeta {index + 1}</p>
						<div>
							<img src={getBrandIcon(metodo?.brand)} className='brand-icon mr-2' alt='marca' />
							&#9679;&#9679;&#9679;&#9679; {metodo?.last4}
						</div>
						<div className='text-danger mt-3'>
							<span className='cursor-pointer' onClick={() => handleEliminarMetodoPago(metodo.id)}>
								<FontAwesomeIcon icon={faTrashAlt} className='mr-3' />
								Eliminar
							</span>
						</div>
					</Card.Body>
				</Card>
			</Col>
		));
	};

	const handleEliminarMetodoPago = async (id: number) => {
		const result = await Swal.fire({
			// title: '¿Estas seguro de querer eliminar este método de pago?',
			text: '¿Estas seguro de querer eliminar este método de pago?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Aceptar',
			cancelButtonText: 'Cancelar',
			customClass: {
				confirmButton: 'btn btn-primary px-5',
				cancelButton: 'btn btn-outline-secondary px-5 mr-2',
				content: 'confirmation-modal-text',
			},
			reverseButtons: true,
			buttonsStyling: false,
		});
		if (result.value) {
			try {
				await http.delete(`payment_sources/${id}`);

				setMetodosPago(metodosPago.filter(metodo => metodo.id !== id));
				// toast.success(<ToastMessage type={'success'}>La acción fue realizada con éxito.</ToastMessage>);
			} catch (error) {
				// toast.error(<ToastMessage type={'error'}>Ha ocurrido un error, intente de nuevo.</ToastMessage>);
			}
		}
	};

	const handleFormSubmit = async (values, actions) => {
		const tokenParams = {
			card: {
				number: values.cardNumber,
				name: values.nombre,
				exp_year: values.ano,
				exp_month: values.mes,
				cvc: values.cvv,
			},
		};

		createCardToken(tokenParams, actions);
	};

	const createCardToken = (tokenParams, actions) => {
		window?.Conekta?.Token.create(
			tokenParams,
			async cardToken => {
				try {
					Swal.fire('Procesando...', 'Espere un momento...', 'info');
					Swal.showLoading();

					const body = {
						token_id: cardToken.id,
					};
					const newMetodoPago = await http.post(`payment_sources`, body);

					setMetodosPago(metodosPago.concat(newMetodoPago));

					Swal.close();
					handleCloseModal();
				} catch (error) {
					Swal.close();
					actions.setSubmitting(false);
					// console.error(error);
					setGenericAlert({
						isOpen: true,
						variant: 'danger',
						message: error.message,
					});
				}
			},
			error => {
				// console.error(error);
				setGenericAlert({
					isOpen: true,
					variant: 'danger',
					message: error.message_to_purchaser || 'Ocurrio un error.',
				});
			}
		);

	};

	const handleAgregarMetodoPago = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setGenericAlert({
			isOpen: false,
			variant: '',
			message: '',
		});
	};

	const getYearOptions = (): JSX.Element[] => {
		let years: JSX.Element[] = [
			<option value={''} disabled>
				Año
			</option>,
		];
		return years.concat(generateYears().map(year => <option key={year}>{year}</option>));
	};

	return (
		<div className='perfil'>
			<div className='container mt-5'>
				<h5>
					<Link to='/perfil' className='text-dark p-3'>
						<FontAwesomeIcon icon={faArrowLeft} className='' />
					</Link>
					Métodos de pago
				</h5>
				<hr />

				<Button variant='primary' className='font-weight-bold px-3 mb-5' onClick={handleAgregarMetodoPago}>
					<FontAwesomeIcon icon={faPlus} className='mr-3' />
					Nueva método de pago
				</Button>

				{metodosPago.length > 0 ? (
					<Row>{getMetodosPago()}</Row>
				) : loading ? (
					<div className='py-5 text-center'>
						<FontAwesomeIcon icon={faSpinner} spin size='lg' className='mr-2' />
						Cargando...
					</div>
				) : (
					<div className='py-5 text-center'>Agrega una dirección para continuar</div>
				)}
			</div>
			<Modal show={showModal} centered onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title className='text-primary font-weight-bold'>Nuevo método de pago</Modal.Title>
				</Modal.Header>
				<Modal.Body className='px-5 py-4'>
					{genericAlert.isOpen ? (
						<RBAlert className='text-left' variant={genericAlert.variant}>
							{genericAlert.message}
						</RBAlert>
					) : null}
					<Formik
						initialValues={initialValues}
						validationSchema={agregarMetodoPagoSchema}
						onSubmit={handleFormSubmit}
						render={({ isSubmitting }: FormikProps<any>) => (
							<Form className='agregar-metodo-pago-form'>
								<RBForm.Group controlId='nombre'>
									<RBForm.Label>Nombre del tarjetahabiente</RBForm.Label>
									<Field name='nombre'>
										{({ field }) => (
											<RBForm.Control {...field} type='nombre' placeholder='Nombre del tarjetahabiente' />
										)}
									</Field>
									<ErrorMessage name='nombre' component={ErrorFeedback} />
								</RBForm.Group>

								<RBForm.Group controlId='cardNumber'>
									<RBForm.Label>Número de tarjeta</RBForm.Label>
									<Field name='cardNumber'>
										{({ field }) => <RBForm.Control {...field} type='cardNumber' placeholder='xxxx-xxxx-xxxx-xxxx' />}
									</Field>
									<ErrorMessage name='cardNumber' component={ErrorFeedback} />
								</RBForm.Group>

								<label className='form-label font-weight-medium d-block'>Fecha de expiración</label>
								<Row>
									<Col md='6'>
										<RBForm.Group controlId='mes'>
											<Field name='mes'>
												{({ field }) => (
													<RBForm.Control {...field} as='select' type='mes'>
														<option value={''} disabled>
															Mes
														</option>
														<option>01</option>
														<option>02</option>
														<option>03</option>
														<option>04</option>
														<option>05</option>
														<option>06</option>
														<option>07</option>
														<option>08</option>
														<option>09</option>
														<option>10</option>
														<option>11</option>
														<option>12</option>
													</RBForm.Control>
												)}
											</Field>
											<ErrorMessage name='mes' component={ErrorFeedback} />
										</RBForm.Group>
									</Col>
									<Col md='6'>
										<RBForm.Group controlId='ano'>
											<Field name='ano'>
												{({ field }) => (
													<RBForm.Control {...field} as='select' type='ano'>
														{getYearOptions()}
													</RBForm.Control>
												)}
											</Field>
											<ErrorMessage name='ano' component={ErrorFeedback} />
										</RBForm.Group>
									</Col>
								</Row>

								<RBForm.Group controlId='cvv'>
									<RBForm.Label>CVV</RBForm.Label>
									<Field name='cvv'>{({ field }) => <RBForm.Control {...field} type='cvv' placeholder='XXX' />}</Field>
									<ErrorMessage name='cvv' component={ErrorFeedback} />
								</RBForm.Group>

								{(genericAlert.isOpen && genericAlert.variant === 'danger') ||
									(!genericAlert.isOpen && !genericAlert.variant) ? (
									<div className='text-center'>
										<Button
											variant='primary'
											type='submit'
											className='btn-wide font-weight-bold mt-5 px-5'
											disabled={isSubmitting}>
											{isSubmitting ? <FontAwesomeIcon icon={faSpinner} pulse size='lg' /> : 'Guardar tarjeta'}
										</Button>
									</div>
								) : null}
							</Form>
						)}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default MetodosPago;

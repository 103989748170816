import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RBAlert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faStore } from '@fortawesome/free-solid-svg-icons';
// Assets
import '../ProcesoPagoStepper.css';
import { METODOS_ENTREGA, METODOS_PAGO } from 'utils/constants';

interface MetodoEntregaProps {
   orden: any;
   setOrden: React.Dispatch<any>;
   hasEnvioGratis: boolean;
   onNextBtnClick: () => void;
}

const MetodoEntrega: React.FC<MetodoEntregaProps> = ({ orden, setOrden, hasEnvioGratis, onNextBtnClick }) => {
   return (
      <>
         <p className='font-weight-bold'>Elige metodo de entrega y tipo de pago</p>

         <Row>
            <Col md='3'>
               <Card
                  className={`card-shadow ${hasEnvioGratis ? 'cursor-pointer' : 'cursor-not-allowed bg-light'} ${
                     orden.metodoEntrega === METODOS_ENTREGA.EN_DOMICILIO && 'active-address'
                  } mb-4 mb-md-0`}
                  onClick={() => {
                     if (hasEnvioGratis)
                        setOrden({
                           ...orden,
                           metodoEntrega: METODOS_ENTREGA.EN_DOMICILIO,
                           metodoPago: METODOS_PAGO.TARJETA,
                        });
                  }}>
                  <Card.Body className='py-2 px-0 text-center text-primary'>
                     <FontAwesomeIcon icon={faTruck} size='lg' className='mr-2' /> Enviar a domicilio
                  </Card.Body>
               </Card>
            </Col>
            <Col md='3'>
               <Card
                  className={`card-shadow cursor-pointer ${
                     orden.metodoEntrega === METODOS_ENTREGA.EN_SUCURSAL ? 'active-address' : ''
                  }`}
                  onClick={() =>
                     setOrden({
                        ...orden,
                        metodoEntrega: METODOS_ENTREGA.EN_SUCURSAL,
                        metodoPago: METODOS_PAGO.TARJETA,
                     })
                  }>
                  <Card.Body className='py-2 px-0 text-center text-primary'>
                     <div>
                        <FontAwesomeIcon icon={faStore} size='lg' className='mr-2' /> Recojer en sucursal
                     </div>
                  </Card.Body>
               </Card>
            </Col>
         </Row>

         {!hasEnvioGratis && (
            <RBAlert className='text-left mt-4' variant='warning'>
               Para tener acceso al envío a domicilio gratis, debes realizar una compra mayor a $500 pesos
            </RBAlert>
         )}

         {orden.metodoEntrega === METODOS_ENTREGA.EN_DOMICILIO && (
            <>
               <Form.Check
                  custom
                  type='radio'
                  id='tarjeta-domicilio'
                  className='mt-4'
                  label='Pagar con Tarjeta.'
                  name='metodoPago'
                  onChange={() => setOrden({ ...orden, metodoPago: METODOS_PAGO.TARJETA })}
                  checked={orden.metodoPago === METODOS_PAGO.TARJETA}
               />
               <Form.Check
                  custom
                  type='radio'
                  id='paypal-radio'
                  className='mt-4'
                  label='Pagar con Paypal.'
                  name='metodoPago'
                  onChange={() => setOrden({ ...orden, metodoPago: METODOS_PAGO.PAYPAL })}
                  checked={orden.metodoPago === METODOS_PAGO.PAYPAL}
               />
               <Form.Check
                  custom
                  type='radio'
                  id='efectivo-domicilio'
                  className='mt-4'
                  label='Pagar en Efectivo. ¿Quieres pagar al recibir el producto?'
                  name='metodoPago'
                  onChange={() => setOrden({ ...orden, metodoPago: METODOS_PAGO.EFECTIVO_DOMICILIO })}
                  checked={orden.metodoPago === METODOS_PAGO.EFECTIVO_DOMICILIO}
               />
            </>
         )}

         {orden.metodoEntrega === METODOS_ENTREGA.EN_SUCURSAL && (
            <>
               <Form.Check
                  custom
                  type='radio'
                  id='tarjeta-sucursal'
                  className='mt-4'
                  label='Pagar con Tarjeta.'
                  name='metodoPago'
                  onChange={() => setOrden({ ...orden, metodoPago: METODOS_PAGO.TARJETA })}
                  checked={orden.metodoPago === METODOS_PAGO.TARJETA}
               />
               <Form.Check
                  custom
                  type='radio'
                  id='paypal-radio'
                  className='mt-4'
                  label='Pagar con Paypal.'
                  name='metodoPago'
                  onChange={() => setOrden({ ...orden, metodoPago: METODOS_PAGO.PAYPAL })}
                  checked={orden.metodoPago === METODOS_PAGO.PAYPAL}
               />
               <Form.Check
                  custom
                  type='radio'
                  id='efectivo-sucursal'
                  className='mt-4'
                  label='Pagar en Efectivo.'
                  name='metodoPago'
                  onChange={() => setOrden({ ...orden, metodoPago: METODOS_PAGO.EFECTIVO })}
                  checked={orden.metodoPago === METODOS_PAGO.EFECTIVO}
               />
            </>
         )}

         <div className='text-center'>
            <Button variant='primary' className='mt-5 font-weight-bold px-5 btn-stepper' onClick={onNextBtnClick}>
               Siguiente
            </Button>
         </div>
      </>
   );
};

export default MetodoEntrega;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram, faWhatsapp, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { faAt, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import logo from '../../../assets/images/Logo.png';
// import terminosyCondiciones from '../../../assets/TÉRMINOS_Y_CONDICIONES.docx';
// import avisoPrivacidad from '../../../assets';
import './Footer.css';

export interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
   return (
      <footer className='footer bg-footer text-white'>
         <div className='container'>
            <div className='row py-5'>
               <div className='col-md-4 text-center text-md-left mb-4 mb-md-0'>
                  <ul className='left-list'>
                     <li className='font-weight-bold'>¡Síguenos en nuestras redes!</li>
                     <li>
                        {/* <FontAwesomeIcon icon={faFacebookSquare} size='2x' className='mr-2' /> */}
                        <a
                           className='text-white mr-2'
                           href='https://www.facebook.com/plomeriayferreteriamedina'
                           target='_blank'
                           rel='noopener noreferrer'>
                           <FontAwesomeIcon icon={faFacebookSquare} size='2x' />
                        </a>
                        <a
                           className='text-white mr-2'
                           href='https://www.instagram.com/plomeria_ferreteria_medina/'
                           target='_blank'
                           rel='noopener noreferrer'>
                           <FontAwesomeIcon icon={faInstagram} size='2x' />
                        </a>
                        <a
                           className='text-white'
                           href='https://www.youtube.com/channel/UCCQ-IeaJ2_gzBTeTtCPLU8A'
                           target='_blank'
                           rel='noopener noreferrer'>
                           <FontAwesomeIcon icon={faYoutubeSquare} size='2x' />
                        </a>
                     </li>
                     <li>
                        <FontAwesomeIcon icon={faAt} size='lg' className='mr-2' />
                        ventasweb@plomeriamedina.com
                     </li>
                     <li>
                        <FontAwesomeIcon icon={faWhatsapp} size='lg' className='mr-2' />
                        631-144-0106
                     </li>
                     <li>
                        <FontAwesomeIcon icon={faPhone} size='lg' className='mr-2' />
                        631-314-7130
                     </li>
                  </ul>
               </div>
               <div className='col-md-4 mb-4 mb-md-0'>
                  <div className='text-center'>
                     <p>
                        <FontAwesomeIcon icon={faMapMarkerAlt} size='2x' />
                     </p>
                     <a
                        className='ubicacion text-white'
                        href='https://www.google.com/maps/place/PLOMER%C3%8DA+Y+FERRETER%C3%8DA+MEDINA+(COLOSIO)/@31.2924502,-110.9480835,18z/data=!4m8!1m2!2m1!1sbldv.+luis+donaldo+colosio,+2500,+Col.+Unidad+Deportiva,+nogales,+son,+c.p.84063!3m4!1s0x86d152c5266c21ab:0x81998aa140711b2c!8m2!3d31.292575!4d-110.9472397'
                        target='_blank'
                        rel='noopener noreferrer'>
                        <p className='text-uppercase'>
                           bldv. luis donaldo colosio, 2500, <br />
                           <span>Col. Deportiva, nogales, son, c.p.84065</span>
                        </p>
                     </a>
                     <ul className='center-list'>
                        <li className='font-weight-bold'>Horario</li>
                        <hr className='mt-0 mb-1' />
                        <li>Lun.-Vie.08:00 - 18:00</li>
                        <li>Sab.08:00 - 14:00</li>
                        <li>Dom.Cerrado</li>
                     </ul>
                  </div>
               </div>
               <div className='col-md-4'>
                  <ul className='right-list text-center text-md-right'>
                     <li>
                        <img src={logo} alt='logo' />
                     </li>
                     <li>
                        <a
                           className='text-white'
                           href={`${process.env.REACT_APP_BASE_URL}TÉRMINOS_Y_CONDICIONES.docx`}
                           target='_blank'
                           rel='noopener noreferrer'>
                           Términos y condiciones
                        </a>
                     </li>
                     <li>
                        <a
                           className='text-white'
                           href={`${process.env.REACT_APP_BASE_URL}AVISO_DE_PRIVACIDAD.docx`}
                           target='_blank'
                           rel='noopener noreferrer'>
                           Aviso de privacidad
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </footer>
   );
};

export default Footer;

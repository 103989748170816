import React from 'react';

export interface PoliticasEntregaProps { }

const PoliticasEntrega: React.FC<PoliticasEntregaProps> = () => {
	return (
		<section id='politicas-entrega'>
			<div className="container my-5">
				<h1 className="text-center">Políticas de entrega</h1>

				<p className="font-weight-bold">Entrega en tienda</p>
				<p className="text-justify">
					Una vez realizada su compra recibirá un correo de confirmación o llamada telefónica
					donde se indicará que su pedido está listo para recoger. Para la recepción de su
					pedido deberá de acudir a la tienda seleccionada con su comprobante de compra o
					folio del pedido, mismo que lo recibirá por correo electrónico y deberá de presentar
					identificación oficial. Solo se hará entrega a la persona que haya realizado la compra o
					persona autorizada.
				</p>
				<p className="text-justify">
					El tiempo máximo para recoger su compra será de 7 días hábiles a partir de su fecha
					de compra. De no recoger en el tiempo estipulado su compra será cancelada
					automáticamente y se procederá a la devolución del monto de la compra realizada, por
					la misma forma que se recibió su pago. Si el pago lo realizó con tarjeta su reembolso
					se verá reflejado en su cuenta en un periodo de 7 a 30 días hábiles dependiendo de las
					politicas del banco donde se haya realizado la transacción, asi como también se
					generará una nota de cargo del 3% del total de la compra por cargos administrativos.
				</p>

				<p className="font-weight-bold">Entrega a domicilio</p>
				<p className="text-justify">
					Toda entrega a domicilio se realizará apartir de $500.00 pesos de compra o más, y se
					realizarán sin cobro alguno dentro de la ciudad de Nogales Sonora. Para envíos fuera
					de la ciudad tendrá que contactarnos antes de realizar la compra en línea para que
					conozca las políticas y cobros de entregas foráneas.
				</p>
				<p className="text-justify">
					Una vez realizada su compra recibirá un correo de confirmación donde se le informará
					que su pedido está listo. Se considera un periodo de 24 a 72 horas a partir del
					momento de la compra para realizar la entrega. La entrega solo podrá ser enviada al
					domicilio que usted indicó al momento de la compra.
				</p>
				<p className="text-justify">
					Las entregas a domicilio se realizaran a línea de calle en el domicilio o hasta donde el
					vehículo pueda ingresar de manera segura. El personal de entregas no podrá realizar
					la entrega si el camino no es apto para vehículo de carga.
				</p>
				<p className="text-justify">
					La empresa se reserva el derecho de negar el servicio de entregas cuando las
					condiciones climatológicas, destino de entrega o maniobras solicitadas por el cliente
					representen un riesgo para los colaboradores o unidades.
				</p>
				<p className="text-justify">
					El tiempo máximo para recibir un envió será de 7 días hábiles a partir de la fecha de
					compra, de no ser posible localizarlo en el domicilio proporcionado o vía telefónica, su
					compra será automáticamente cancelada y se realizara el rembolso correspondiente en
					la misma forma en que se haya realizado el pago. Si el pago lo realizó con tarjeta su
					reembolso se verá reflejado en su cuenta en un periodo de 7 a 30 días hábiles
					dependiendo de las politicas del banco donde se haya realizado la transacción, asi
					como también se generará una nota de cargo del 3% del total de la compra por cargos
					administrativos.
				</p>
				<p className="text-justify">
					El cliente deberá de revisar que el material recibido se encuentre en buen estado antes
					de realizar la firma de conformidad, una vez recibida la mercancía no se aceptan
					devoluciones posteriores por material dañado o en mal estado.
				</p>
			</div>
		</section>
	);
};

export default PoliticasEntrega;

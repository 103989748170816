import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Ordenes from 'components/Dashboard/Ordenes/Ordenes';
import Usuarios from 'components/Dashboard/Usuarios/Usuarios';
import Banners from 'components/Dashboard/Banners/Banners';
import CarouselImgList from 'components/Dashboard/Carousel/CarouselImgList';

const DashboardRoutes: React.FC = () => {
   return (
      <Switch>
         <Route path={`/r/ordenes`} component={Ordenes} />

         <Route path={`/r/usuarios`} component={Usuarios} />

         <Route path={`/r/banners`} component={Banners} />

         <Route path={`/r/carousel`} component={CarouselImgList} />
         {/* No routes match 404 */}
         <Route path='*' component={Ordenes} />
      </Switch>
   );
};

export default DashboardRoutes;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
// Mis Componentes
import http from 'services/http.service';
import { getCurrentUser } from 'services/authentication.service';
import { getImporteTotal, getIVATotal, getPrecioBruto, getPrecioNeto } from 'utils';
import { PRODUCTO_IMAGE_PLACEHOLDER } from 'utils/constants';
import './Carrito.css';

export interface CarritoProps {
   setCartCounter: React.Dispatch<any>;
}

const Carrito: React.FC<CarritoProps> = ({ setCartCounter }) => {
   let history = useHistory();
   const usuario = getCurrentUser();
   const [carrito, setCarrito] = useState<any[]>(
      localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart') as string) : []
   );
   const [subtotal, setSubtotal] = useState<number>(0);

   useEffect(() => {
      const fetchProductosInfo = async () => {
         try {
            const carritoData: any[] = await http.post(`/carrito`, { carrito });
            setCarrito(carritoData);
            localStorage.setItem('cart', JSON.stringify(carritoData));
         } catch (error) {
            console.error(error);
         }
      };

      if (carrito.length > 0) {
         fetchProductosInfo();
      }
   }, []);

   useEffect(() => {
      let sumaSubtotal: number = 0;
      carrito.forEach(producto => (sumaSubtotal += getPrecioBruto(producto, producto.cantidad)));
      setSubtotal(sumaSubtotal);
      setCartCounter(carrito.length);
   }, [carrito]);

   const getProductos = () => {
      return carrito.map(producto => (
         <tr key={producto.id}>
            <td>
               <img
                  className='img-fluid'
                  src={producto.imagen ? producto.imagen : PRODUCTO_IMAGE_PLACEHOLDER}
                  alt='imagen producto'
               />
            </td>
            <td>
               <p className='product-name'>{producto.descripcion}</p>
               <p className='text-gray'>Codigo: {producto.codigo}</p>
               <Row>
                  <Col md='3'>
                     <Form.Group controlId='cantidad'>
                        <Form.Label className='d-block'>Cantidad</Form.Label>
                        <Form.Control
                           name={`cantidad-${producto.id}`}
                           as='select'
                           custom
                           onChange={handleCantidadChange}
                           value={producto.cantidad}>
                           {getCantidadOptions(producto)}
                        </Form.Control>
                     </Form.Group>
                  </Col>
                  <Col md='3'>
                     <Form.Group controlId='precio'>
                        <Form.Label className='d-block'>Precio</Form.Label>
                        <div className='py-2'>${getPrecioNeto(producto, producto.cantidad)}</div>
                     </Form.Group>
                  </Col>
               </Row>
            </td>
            <td>
               <div className='text-danger text-right'>
                  <span className='cursor-pointer' onClick={() => handleEliminarProducto(producto.id)}>
                     <FontAwesomeIcon icon={faTrashAlt} className='mr-2' />
                     Eliminar
                  </span>
               </div>
            </td>
         </tr>
      ));
   };

   const handleEliminarProducto = async (id: number) => {
      const result = await Swal.fire({
         text: '¿Estas seguro que quieres eliminar este producto?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Aceptar',
         cancelButtonText: 'Cancelar',
         customClass: {
            confirmButton: 'btn btn-primary px-5',
            cancelButton: 'btn btn-outline-secondary px-5 mr-2',
            content: 'confirmation-modal-text',
         },
         reverseButtons: true,
         buttonsStyling: false,
      });
      if (result.value) {
         try {
            const carritoUpdated: any[] = carrito.filter(producto => producto.id !== id);
            localStorage.setItem('cart', JSON.stringify(carritoUpdated));
            setCarrito(localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart') as string) : []);
         } catch (error) {}
      }
   };

   const handleCantidadChange = ({ currentTarget: input }: React.FormEvent<HTMLSelectElement>) => {
      const productoId = Number(input.name.split('-')[1]);
      const mappedCart = carrito.map(producto => {
         if (producto.id === productoId) {
            return {
               ...producto,
               cantidad: Number(input.value),
            };
         } else {
            return producto;
         }
      });
      // Actualizar estado y localstorage
      setCarrito(mappedCart);
      localStorage.setItem('cart', JSON.stringify(mappedCart));
   };

   const handleProcederPago = () => {
      if (!usuario) {
         history.push({
            pathname: '/entrar',
            state: { prevRoute: '/proceso-pago' },
         });
      } else {
         history.push('/proceso-pago');
      }
   };

   const getCantidadOptions = producto => {
      let options: JSX.Element[] = [];
      if (producto) {
         for (let i = 1; i <= producto.existencia; i++) {
            options.push(<option key={i}>{i}</option>);
         }
         return options;
      }
      return options;
   };

   return (
      <div className='carrito'>
         <div className='container mt-5'>
            <h5>
               <FontAwesomeIcon icon={faShoppingCart} className='text-primary mr-3' />
               Carrito de compras
            </h5>
            <hr />
            <div className='row mt-4 mb-5'>
               <div className='col-md-9'>
                  <Card className='carrito-productos-card mb-4 mb-md-0'>
                     <Card.Body>
                        <Table borderless responsive>
                           <thead>
                              <tr>
                                 <th style={{ width: '20%' }} className='p-0'></th>
                                 <th style={{ width: '60%' }} className='p-0'></th>
                                 <th style={{ width: '20%' }} className='p-0'></th>
                              </tr>
                           </thead>
                           <tbody>
                              {getProductos().length > 0 ? (
                                 getProductos()
                              ) : (
                                 <tr>
                                    <td colSpan={3}>
                                       <p className='product-name text-center'>Aún no has agregado productos al carrito</p>
                                    </td>
                                 </tr>
                              )}
                           </tbody>
                        </Table>
                     </Card.Body>
                  </Card>
               </div>
               <div className='col-md-3'>
                  <Card className='resumen-card'>
                     <Card.Body>
                        <div className='row no-gutters'>
                           <div className='col-md-4'>
                              <p className='text-gray text-center text-md-left'>Subtotal:</p>
                           </div>
                           <div className='col-md-8'>
                              <p className='text-gray text-center text-md-right'>${subtotal.toFixed(2)}</p>
                           </div>
                        </div>
                        <div className='row no-gutters'>
                           <div className='col-md-4'>
                              <p className='text-gray text-center text-md-left'>IVA:</p>
                           </div>
                           <div className='col-md-8'>
                              <p className='text-gray text-center text-md-right'>${getIVATotal(subtotal)}</p>
                           </div>
                        </div>

                        <hr />

                        <div className='row no-gutters'>
                           <div className='col-md-4'>
                              <p className='carrito-total text-gray text-center text-md-left'>Total:</p>
                           </div>
                           <div className='col-md-8'>
                              <p className='carrito-total text-gray text-center text-md-right'>${getImporteTotal(subtotal)}</p>
                           </div>
                        </div>

                        <Button
                           variant='primary'
                           className='mt-4 font-weight-bold'
                           block
                           disabled={!subtotal}
                           onClick={handleProcederPago}>
                           Proceder al pago
                        </Button>
                     </Card.Body>
                  </Card>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Carrito;

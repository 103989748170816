import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import RBAlert from 'react-bootstrap/Alert';
import Swal from 'sweetalert2';
// Mis Componentes
import http from 'services/http.service';
import MetodoEntrega from './steps/MetodoEntrega';
import ElegirDireccion from './steps/ElegirDireccion';
import ElegirSucursal from './steps/ElegirSucursal';
import ElegirTarjeta from './steps/ElegirTarjeta';
import Resumen from './steps/Resumen';
import '../Carrito.css';
import './ProcesoPagoStepper.css';
import { METODOS_ENTREGA, METODOS_PAGO } from 'utils/constants';
import { getPrecioNeto } from 'utils';

export interface ProcesoPagoStepperProps {
   setCartCounter: React.Dispatch<any>;
}

const ProcesoPagoStepper: React.FC<ProcesoPagoStepperProps> = ({ setCartCounter }) => {
   let history = useHistory();
   const [orden, setOrden] = useState<any>({
      metodoEntrega: METODOS_ENTREGA.EN_DOMICILIO,
      metodoPago: METODOS_PAGO.TARJETA,
      direccion: undefined,
      sucursal: undefined,
      tarjeta: undefined,
      carrito: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart') as string) : [],
   });
   const [total, setTotal] = useState<number>(0);
   const [tabSeccionKey, setTabSeccionKey] = useState<string>('MetodoEntrega');
   const [genericAlert, setGenericAlert] = useState<any>({
      isOpen: false,
      variant: '',
      message: '',
   });

   useEffect(() => {
      const fetchProductosInfo = async () => {
         try {
            const carritoData: any[] = await http.post(`/carrito`, { carrito: orden.carrito });
            setOrden({ ...orden, carrito: carritoData });
            localStorage.setItem('cart', JSON.stringify(carritoData));
         } catch (error) {
            console.error(error);
         }
      };

      fetchProductosInfo();
   }, []);

   useEffect(() => {
      let totalData: number = orden.carrito.reduce((total, producto) => (total += getPrecioNeto(producto, producto.cantidad)), 0);
      setTotal(totalData);
      if (totalData < 500) {
         setOrden({
            ...orden,
            metodoEntrega: METODOS_ENTREGA.EN_SUCURSAL,
         });
      }
   }, [orden.carrito]);

   useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://cdn.conekta.io/js/latest/conekta.js';
      script.async = true;
      document.body.appendChild(script);
   }, []);

   const handleBackBtnClick = () => {
      switch (tabSeccionKey) {
         case 'LugarEntrega': {
            setTabSeccionKey('MetodoEntrega');
            clearAlert();
            break;
         }
         case 'ElegirTarjeta': {
            setTabSeccionKey('LugarEntrega');
            clearAlert();
            break;
         }
         case 'Resumen': {
            if (
               orden.metodoEntrega === METODOS_ENTREGA.EN_DOMICILIO &&
               orden.metodoPago === METODOS_PAGO.TARJETA &&
               orden.direccion
            ) {
               setTabSeccionKey('ElegirTarjeta');
            } else if (
               orden.metodoEntrega === METODOS_ENTREGA.EN_DOMICILIO &&
               orden.metodoPago === METODOS_PAGO.EFECTIVO_DOMICILIO &&
               orden.direccion
            ) {
               setTabSeccionKey('LugarEntrega');
            } else if (
               orden.metodoEntrega === METODOS_ENTREGA.EN_DOMICILIO &&
               orden.metodoPago === METODOS_PAGO.PAYPAL &&
               orden.direccion
            ) {
               setTabSeccionKey('LugarEntrega');
            } else if (orden.metodoEntrega === METODOS_ENTREGA.EN_SUCURSAL && orden.sucursal) {
               setTabSeccionKey('LugarEntrega');
            }
            clearAlert();
            break;
         }
      }
   };

   const handleNextBtnClick = () => {
      switch (tabSeccionKey) {
         case 'MetodoEntrega':
            setTabSeccionKey('LugarEntrega');
            break;
         case 'LugarEntrega': {
            if (
               orden.metodoEntrega === METODOS_ENTREGA.EN_DOMICILIO &&
               orden.metodoPago === METODOS_PAGO.TARJETA &&
               orden.direccion
            ) {
               setTabSeccionKey('ElegirTarjeta');
               clearAlert();
            } else if (
               orden.metodoEntrega === METODOS_ENTREGA.EN_DOMICILIO &&
               orden.metodoPago === METODOS_PAGO.EFECTIVO_DOMICILIO &&
               orden.direccion
            ) {
               setTabSeccionKey('Resumen');
               clearAlert();
            } else if (
               orden.metodoEntrega === METODOS_ENTREGA.EN_DOMICILIO &&
               orden.metodoPago === METODOS_PAGO.PAYPAL &&
               orden.direccion
            ) {
               setTabSeccionKey('Resumen');
               clearAlert();
            } else if (
               orden.metodoEntrega === METODOS_ENTREGA.EN_SUCURSAL &&
               orden.metodoPago === METODOS_PAGO.TARJETA &&
               orden.sucursal
            ) {
               setTabSeccionKey('ElegirTarjeta');
               clearAlert();
            } else if (
               orden.metodoEntrega === METODOS_ENTREGA.EN_SUCURSAL &&
               orden.metodoPago === METODOS_PAGO.EFECTIVO &&
               orden.sucursal
            ) {
               setTabSeccionKey('Resumen');
               clearAlert();
            } else if (
               orden.metodoEntrega === METODOS_ENTREGA.EN_SUCURSAL &&
               orden.metodoPago === METODOS_PAGO.PAYPAL &&
               orden.sucursal
            ) {
               setTabSeccionKey('Resumen');
               clearAlert();
            } else {
               setGenericAlert({
                  isOpen: true,
                  variant: 'danger',
                  message: 'Debes seleccionar una opción para poder continuar.',
               });
            }
            break;
         }
         case 'ElegirTarjeta': {
            if (orden.tarjeta) {
               setTabSeccionKey('Resumen');
               clearAlert();
            } else {
               setGenericAlert({
                  isOpen: true,
                  variant: 'danger',
                  message: 'Debes seleccionar una tarjeta para poder continuar.',
               });
            }
            break;
         }
      }
   };

   const clearAlert = () => {
      setGenericAlert({
         isOpen: false,
         variant: '',
         message: '',
      });
   };

   const handleConfirmarOrden = async () => {
      try {
         Swal.fire('Procesando su compra...', 'Espere un momento...', 'info');
         Swal.showLoading();

         const body = {
            metodo_entrega: orden.metodoEntrega,
            metodo_pago: orden.metodoPago,
            ...(orden.metodoPago === METODOS_PAGO.TARJETA ? { payment_source_id: orden.tarjeta?.id } : {}),
            ...(orden.direccion?.direccion_id
               ? { direccion_id: orden.direccion?.direccion_id }
               : { sucursal_id: orden.sucursal?.sucursal_id }),
            carrito: orden.carrito,
         };
         await http.post(`ordenes`, body);

         // Resetear el carrito despues de la compra
         setOrden({
            ...orden,
            carrito: [],
         });
         localStorage.setItem('cart', JSON.stringify([]));
         setCartCounter(0);
         history.replace('/gracias-por-tu-compra');

         Swal.close();
      } catch (error) {
         console.log(error);
         Swal.fire('Error', 'Ocurrio un error al procesar tu compra, por favor intenta de nuevo.', 'error');
      }
   };

   const handlePayPalOrden = async payPalOrdenID => {
      try {
         Swal.fire('Procesando su orden...', 'Espere un momento...', 'info');
         Swal.showLoading();

         const body = {
            metodo_entrega: orden.metodoEntrega,
            metodo_pago: orden.metodoPago,
            paypal_orden_id: payPalOrdenID,
            ...(orden.direccion?.direccion_id
               ? { direccion_id: orden.direccion?.direccion_id }
               : { sucursal_id: orden.sucursal?.sucursal_id }),
            carrito: orden.carrito,
         };
         await http.post(`ordenes`, body);

         // Resetear el carrito despues de la compra
         setOrden({
            ...orden,
            carrito: [],
         });
         localStorage.setItem('cart', JSON.stringify([]));
         setCartCounter(0);
         history.replace('/gracias-por-tu-compra');

         Swal.close();
      } catch (error) {
         console.log(error);
         Swal.fire('Error', 'Ocurrio un error al procesar tu compra, por favor intenta de nuevo.', 'error');
      }
   };

   return (
      <div className='proceso-pago'>
         <div className='container mt-5'>
            <h5>Proceso de compra</h5>
            <hr />
            <Tabs id='proceso-pago' className='tabs-proceso-pago mt-3 mb-4' activeKey={tabSeccionKey} onSelect={key => {}}>
               <Tab eventKey='MetodoEntrega' title='Metodo de Entrega'>
                  <Card className='card-shadow'>
                     <Card.Body className='p-4'>
                        {genericAlert.isOpen ? (
                           <RBAlert className='' variant={genericAlert.variant}>
                              {genericAlert.message}
                           </RBAlert>
                        ) : null}
                        <MetodoEntrega
                           orden={orden}
                           setOrden={setOrden}
                           hasEnvioGratis={total > 500}
                           onNextBtnClick={handleNextBtnClick}
                        />
                     </Card.Body>
                  </Card>
               </Tab>
               <Tab
                  eventKey='LugarEntrega'
                  title={orden.metodoEntrega === METODOS_ENTREGA.EN_DOMICILIO ? 'Elegir Direccion' : 'Elegir Sucursal'}>
                  <Card className='card-shadow mb-5'>
                     <Card.Body className='p-4'>
                        {genericAlert.isOpen ? (
                           <RBAlert className='' variant={genericAlert.variant}>
                              {genericAlert.message}
                           </RBAlert>
                        ) : null}
                        {orden.metodoEntrega === METODOS_ENTREGA.EN_DOMICILIO ? (
                           <ElegirDireccion
                              orden={orden}
                              setOrden={setOrden}
                              onBackBtnClick={handleBackBtnClick}
                              onNextBtnClick={handleNextBtnClick}
                           />
                        ) : (
                           <ElegirSucursal
                              orden={orden}
                              setOrden={setOrden}
                              onBackBtnClick={handleBackBtnClick}
                              onNextBtnClick={handleNextBtnClick}
                           />
                        )}
                     </Card.Body>
                  </Card>
               </Tab>
               {orden.metodoPago === METODOS_PAGO.TARJETA && (
                  <Tab eventKey='ElegirTarjeta' title='Elegir Tarjeta'>
                     <Card className='card-shadow'>
                        <Card.Body className='p-4'>
                           {genericAlert.isOpen ? (
                              <RBAlert className='' variant={genericAlert.variant}>
                                 {genericAlert.message}
                              </RBAlert>
                           ) : null}
                           <ElegirTarjeta
                              orden={orden}
                              setOrden={setOrden}
                              onBackBtnClick={handleBackBtnClick}
                              onNextBtnClick={handleNextBtnClick}
                           />
                        </Card.Body>
                     </Card>
                  </Tab>
               )}
               <Tab eventKey='Resumen' title='Resumen'>
                  {genericAlert.isOpen ? (
                     <RBAlert className='' variant={genericAlert.variant}>
                        {genericAlert.message}
                     </RBAlert>
                  ) : null}
                  <Resumen
                     orden={orden}
                     onBackBtnClick={handleBackBtnClick}
                     onCreatePayPalOrden={handlePayPalOrden}
                     onConfirmarOrden={handleConfirmarOrden}
                  />
               </Tab>
            </Tabs>
         </div>
      </div>
   );
};

export default ProcesoPagoStepper;

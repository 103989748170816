import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// Mis Componentes
import { getBrandIcon, getImporteTotal, getIVATotal, getPrecioBruto, getPrecioNeto } from 'utils';
import { PRODUCTO_IMAGE_PLACEHOLDER, METODOS_ENTREGA, METODOS_PAGO_DESC, METODOS_PAGO } from 'utils/constants';
import '../ProcesoPagoStepper.css';
import PayPalButton from 'components/shared/PaypalButton';

interface ResumenProps {
   orden: any;
   onBackBtnClick: () => void;
   onCreatePayPalOrden: (paypalOrdenID) => Promise<void>;
   onConfirmarOrden: () => Promise<void>;
}

const Resumen: React.FC<ResumenProps> = ({ orden, onBackBtnClick, onCreatePayPalOrden, onConfirmarOrden }) => {
   const [subtotal, setSubtotal] = useState<number>(0);

   useEffect(() => {
      let sumaSubtotal: number = 0;
      orden.carrito.forEach(producto => (sumaSubtotal += getPrecioBruto(producto, producto.cantidad)));
      setSubtotal(sumaSubtotal);
   }, [orden.carrito]);

   const getProductos = () => {
      return orden.carrito.map(producto => (
         <tr key={producto.id}>
            <td>
               <img
                  className='img-fluid'
                  src={producto.imagen ? producto.imagen : PRODUCTO_IMAGE_PLACEHOLDER}
                  alt='imagen producto'
               />
            </td>
            <td>
               <p className='product-name'>{producto.descripcion}</p>
               <p className='text-gray'>Codigo: {producto.codigo}</p>
               <Row>
                  <Col md='3'>
                     <Form.Group controlId='quantity' className='mb-0'>
                        <Form.Label className='d-block'>Cantidad</Form.Label>
                        <div className=''>{producto.cantidad}</div>
                     </Form.Group>
                  </Col>
                  <Col md='3'>
                     <Form.Group controlId='precio' className='mb-0'>
                        <Form.Label className='d-block'>Precio</Form.Label>
                        <div className=''>${getPrecioNeto(producto, producto.cantidad)}</div>
                     </Form.Group>
                  </Col>
               </Row>
            </td>
         </tr>
      ));
   };

   return (
      <>
         <Row className='mt-4 mb-5'>
            <Col md='9'>
               <Card className='card-shadow mb-4 mb-md-0'>
                  <Card.Body>
                     <Table borderless>
                        <thead>
                           <tr>
                              <th style={{ width: '20%' }} className='p-0'></th>
                              <th style={{ width: '80%' }} className='p-0'></th>
                           </tr>
                        </thead>
                        <tbody>
                           {orden.carrito.length > 0 ? (
                              getProductos()
                           ) : (
                              <tr>
                                 <td colSpan={2}>
                                    <p className='product-name text-center'>Aún no has agregado productos al carrito</p>
                                 </td>
                              </tr>
                           )}
                        </tbody>
                     </Table>
                  </Card.Body>
               </Card>
            </Col>
            <Col md='3'>
               <Card className='resumen-card'>
                  <Card.Body>
                     <p className='important-text text-center text-md-left'>Metodo de Entrega</p>
                     <hr />

                     <div className='text-gray mb-1 text-center text-md-left'>
                        {orden.metodoEntrega === METODOS_ENTREGA.EN_DOMICILIO ? 'Envio a domicilio' : 'Recoger en sucursal'}
                     </div>

                     {orden.metodoEntrega === METODOS_ENTREGA.EN_DOMICILIO ? (
                        <>
                           <p className='important-text mt-3 text-center text-md-left'>Dirección</p>
                           <hr />

                           <div className='text-gray mb-1 text-center text-md-left'>
                              {`${orden.direccion?.calle} ${orden.direccion?.numero}, ${orden.direccion?.colonia}`}.
                           </div>
                           <div className='text-gray mb-1 text-center text-md-left'>{`${orden.direccion?.codigo_postal ?? ''} ${
                              orden.direccion?.Ciudad?.nombre || 'Nogales'
                           }, Sonora`}</div>
                           {/* <div className='text-gray mb-1'>Lorem@correo.com</div> */}
                        </>
                     ) : (
                        <>
                           <p className='important-text mt-3 text-center text-md-left'>Sucursal</p>
                           <hr />

                           <div className='text-gray mb-1'>
                              {`${orden.sucursal?.calle} ${orden.sucursal?.numero}, ${orden.sucursal?.colonia}`}.
                           </div>
                           <div className='text-gray mb-1'>{`${orden.sucursal?.codigo_postal ?? ''} ${
                              orden.sucursal?.Ciudad?.nombre
                           }, Sonora`}</div>
                        </>
                     )}

                     <p className='important-text mt-3 text-center text-md-left'>Método de pago</p>
                     <hr />
                     <div className='text-gray mb-1 text-center text-md-left'>{METODOS_PAGO_DESC[orden.metodoPago]}</div>

                     {orden.metodoPago === METODOS_PAGO.TARJETA && (
                        <>
                           <p className='important-text mt-3 text-center text-md-left'>Tarjeta</p>
                           <hr />
                           <div className='text-center text-md-left'>
                              <img src={getBrandIcon(orden.tarjeta?.brand)} className='brand-icon mr-2' alt='marca' />
                              &#9679;&#9679;&#9679;&#9679; {orden.tarjeta?.last4}
                           </div>
                        </>
                     )}

                     <p className='important-text mt-3 text-center text-md-left'>A pagar</p>
                     <hr />
                     <Row>
                        <Col md='6'>
                           <p className='text-gray text-center text-md-left'>Subtotal:</p>
                        </Col>
                        <Col md='6'>
                           <p className='text-gray text-center text-md-right'>${subtotal.toFixed(2)}</p>
                        </Col>
                     </Row>
                     <Row>
                        <Col md='6'>
                           <p className='text-gray text-center text-md-left'>IVA:</p>
                        </Col>
                        <Col md='6'>
                           <p className='text-gray text-center text-md-right'>${getIVATotal(subtotal)}</p>
                        </Col>
                     </Row>

                     <hr />

                     <Row noGutters className='mb-3'>
                        <Col md='5'>
                           <div className='carrito-total text-gray text-center text-md-left'>Total:</div>
                        </Col>
                        <Col md='7'>
                           <div className='carrito-total text-gray text-center text-md-right'>${getImporteTotal(subtotal)}</div>
                        </Col>
                     </Row>

                     {orden.metodoPago === METODOS_PAGO.PAYPAL ? (
                        <PayPalButton
                           orden={orden}
                           onCreatePayPalOrden={onCreatePayPalOrden}
                           // subtotal={subtotal}
                           total={getImporteTotal(subtotal)}
                        />
                     ) : (
                        <Button variant='primary' className='font-weight-bold px-5 btn-block' onClick={onConfirmarOrden}>
                           Confirmar
                        </Button>
                     )}
                  </Card.Body>
               </Card>
            </Col>
         </Row>
         <Row className='my-5'>
            <Col md='6' className='text-center d-md-flex justify-content-md-end mb-4 mb-md-0'>
               <Button variant='light' className='font-weight-bold px-5 btn-stepper text-primary' onClick={onBackBtnClick}>
                  Atras
               </Button>
            </Col>
            {/* {orden.metodoPago !== METODOS_PAGO.PAYPAL && (
               <Col md='6' className='text-center text-md-left'>
                  <Button variant='primary' className='font-weight-bold px-5 btn-stepper' onClick={onConfirmarOrden}>
                     Confirmar
                  </Button>
               </Col>
            )} */}
         </Row>
      </>
   );
};

export default Resumen;

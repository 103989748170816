import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import RBAlert from 'react-bootstrap/Alert';
import RBForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faCreditCard, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
// Mis Componentes
import ErrorFeedback from 'components/shared/ErrorFeedback';
import http from 'services/http.service';
import { getCurrentUser } from 'services/authentication.service';
import './Perfil.css';

export interface PerfilProps {}

const Perfil: React.FC<PerfilProps> = () => {
   const usuario = getCurrentUser();
   const [showModal, setShowModal] = useState(false);
   const [genericAlert, setGenericAlert] = useState<any>({
      isOpen: false,
      variant: '',
      message: '',
   });
   const initialValues = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
   };
   const cambiarPasswordSchema = Yup.object().shape({
      currentPassword: Yup.string()
         .min(6, 'Mínimo 6 caracteres')
         .max(50, 'Maximo 50 caracteres')
         .trim()
         .required('Campo requerido'),
      newPassword: Yup.string().min(6, 'Mínimo 6 caracteres').max(50, 'Maximo 50 caracteres').trim().required('Campo requerido'),
      confirmNewPassword: Yup.string()
         .oneOf([Yup.ref('newPassword'), null], 'Las contraseñas no coinciden')
         .trim()
         .required('Campo requerido'),
   });

   const handleFormSubmit = async (values, actions) => {
      try {
         Swal.fire('Procesando...', 'Espere un momento...', 'info');
         Swal.showLoading();

         const body = {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
            confirmNewPassword: values.confirmNewPassword,
         };
         await http.put(`users/${usuario.usuario_id}/password`, body);

         Swal.close();
         setGenericAlert({
            isOpen: true,
            variant: 'success',
            message: 'Tu contraseña se ha cambiado correctamente.',
         });
      } catch (error) {
         Swal.close();
         actions.setSubmitting(false);
         // apiErrorHandler('Usuario', error);
         if (error.type === 'Usuario/WrongPassword') {
            setGenericAlert({
               isOpen: true,
               variant: 'danger',
               message: error.message,
            });
         }
      }
   };

   const handleCambiarPasswordClick = () => {
      setShowModal(true);
   };

   const handleCloseModal = () => {
      setShowModal(false);
      setGenericAlert({
         isOpen: false,
         variant: '',
         message: '',
      });
   };

   useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://cdn.conekta.io/js/latest/conekta.js';
      script.async = true;
      document.body.appendChild(script);
   }, []);

   return (
      <div className='perfil'>
         <div className='container mt-5'>
            <h5>Mi perfil</h5>
            <hr />

            <Row className='mb-5 text-center text-md-left'>
               <Col md='4'>
                  <div className='font-weight-medium'>Nombre</div>
                  <p>{`${usuario.nombres} ${usuario.apellidos}`}</p>
               </Col>
               <Col md='4'>
                  <div className='font-weight-medium'>Correo electrónico</div>
                  <p>{usuario.email}</p>
               </Col>
               <Col md='4' className='align-self-center'>
                  <span className='cursor-pointer text-primary font-weight-medium' onClick={handleCambiarPasswordClick}>
                     Cambiar contraseña
                  </span>
               </Col>
            </Row>

            <Row>
               <Col md='4' className='mb-4 mb-md-0'>
                  <Link to='/perfil/direcciones' className='text-primary'>
                     <Card className='card-shadow'>
                        <Card.Body className='py-3 text-center'>
                           <FontAwesomeIcon icon={faTruck} size='lg' className='mr-2' /> Direcciones de envío
                        </Card.Body>
                     </Card>
                  </Link>
               </Col>
               <Col md='4'>
                  <Link to='/perfil/metodos-pago' className='text-primary'>
                     <Card className='card-shadow'>
                        <Card.Body className='py-3 text-center'>
                           <FontAwesomeIcon icon={faCreditCard} size='lg' className='mr-2' /> Métodos de pago
                        </Card.Body>
                     </Card>
                  </Link>
               </Col>
               <Col md='4'></Col>
            </Row>
         </div>
         <Modal show={showModal} centered onHide={handleCloseModal}>
            <Modal.Header closeButton>
               <Modal.Title className='text-primary font-weight-bold'>Cambiar contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-5 py-4'>
               {genericAlert.isOpen ? (
                  <RBAlert className='text-left' variant={genericAlert.variant}>
                     {genericAlert.message}
                  </RBAlert>
               ) : null}
               <Formik
                  initialValues={initialValues}
                  validationSchema={cambiarPasswordSchema}
                  onSubmit={handleFormSubmit}
                  render={({ isSubmitting }: FormikProps<any>) => (
                     <Form className='change-password-form'>
                        <RBForm.Group controlId='currentPassword'>
                           <RBForm.Label>Contraseña actual</RBForm.Label>
                           <Field name='currentPassword'>{({ field }) => <RBForm.Control {...field} type='password' />}</Field>
                           <ErrorMessage name='currentPassword' component={ErrorFeedback} />
                        </RBForm.Group>
                        <RBForm.Group controlId='newPassword'>
                           <RBForm.Label>Nueva contraseña</RBForm.Label>
                           <Field name='newPassword'>{({ field }) => <RBForm.Control {...field} type='password' />}</Field>
                           <ErrorMessage name='newPassword' component={ErrorFeedback} />
                        </RBForm.Group>
                        <RBForm.Group controlId='confirmNewPassword'>
                           <RBForm.Label>Confirmar nueva contraseña</RBForm.Label>
                           <Field name='confirmNewPassword'>
                              {({ field }) => (
                                 <RBForm.Control {...field} type='password' placeholder='Repita su nueva contraseña' />
                              )}
                           </Field>
                           <ErrorMessage name='confirmNewPassword' component={ErrorFeedback} />
                        </RBForm.Group>

                        {(genericAlert.isOpen && genericAlert.variant === 'danger') ||
                        (!genericAlert.isOpen && !genericAlert.variant) ? (
                           <div className='text-center'>
                              <Button
                                 variant='primary'
                                 type='submit'
                                 className='btn-wide font-weight-bold mt-5 px-5'
                                 disabled={isSubmitting}>
                                 {isSubmitting ? <FontAwesomeIcon icon={faSpinner} pulse size='lg' /> : 'Cambiar contraseña'}
                              </Button>
                           </div>
                        ) : null}
                     </Form>
                  )}
               />
            </Modal.Body>
         </Modal>
      </div>
   );
};

export default Perfil;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { hasValidToken, getCurrentUser } from 'services/authentication.service';

const DashboardGuard = ({ component: Component, ...rest }) => (
   <Route
      {...rest}
      render={props => {
         if (hasValidToken() && getCurrentUser().is_admin) {
            return <Component {...props} />;
         }
         return <Redirect to='/entrar' />;
      }}
   />
);

export default DashboardGuard;

import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import RBAlert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
// Mis componentes
import http from 'services/http.service';
import useScrollTop from 'hooks/useScrollTop';
import { getPrecioNeto } from 'utils';
import { PRODUCTO_IMAGE_PLACEHOLDER } from 'utils/constants';
import './Producto.css';

export interface ProductoProps {
   setCartCounter: React.Dispatch<any>;
}

const Producto: React.FC<ProductoProps> = ({ setCartCounter }) => {
   let { id } = useParams();
   let history = useHistory();
   const [producto, setProducto] = useState<any>();
   const [categorias, setCategorias] = useState<any[]>([]);
   const [productosSimilares, setProductosSimilares] = useState<any>([]);
   const [genericAlert, setGenericAlert] = useState<any>({
      isOpen: false,
      variant: '',
      message: '',
   });

   useScrollTop();

   useEffect(() => {
      setGenericAlert({
         isOpen: false,
         variant: '',
         message: '',
      });
      const fetchProducto = async id => {
         try {
            const productoDetalles: any = await http.get(`productos/${id}`);
            setProducto({ ...productoDetalles, cantidad: 1 });
         } catch (error) {}
      };
      const fetchCategorias = async () => {
         try {
            const categoriasData: any = await http.get(`productos/categorias`);
            setCategorias(categoriasData);
         } catch (error) {}
      };

      fetchCategorias();
      fetchProducto(id);
   }, [id]);

   useEffect(() => {
      const fetchProductosSimilares = async () => {
         try {
            const categoriaId = producto.linea;
            const { results: productosSimilaresDetalles }: any = await http.get(
               `productos/categorias/${categoriaId}/productos?limit=6`
            );
            setProductosSimilares(productosSimilaresDetalles);
         } catch (error) {
            console.error(error);
         }
      };

      if (producto) {
         fetchProductosSimilares();
      }
   }, [producto]);

   const getProductosSimilares = (): any[] => {
      return productosSimilares.map(producto => (
         <div key={producto.id} className='col-md-2'>
            <Link to={`${producto.id}`}>
               <Card className='product-card'>
                  <div
                     className='product-image'
                     style={{
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundImage: `url(${producto.imagen_url ? producto.imagen_url : PRODUCTO_IMAGE_PLACEHOLDER})`,
                        height: '215px',
                     }}></div>
                  {/* <Card.Img variant='top' src={producto.imagen_url ? producto.imagen_url : PRODUCTO_IMAGE_PLACEHOLDER} /> */}
                  <Card.Body className='pt-3 px-1 pb-1'>
                     <div className='mb-3'>
                        <Card.Text className='text-dark product-name' title={producto.descripcion.toLowerCase()}>
                           {producto.descripcion.toLowerCase()}
                        </Card.Text>
                     </div>
                     <Card.Text className='text-primary product-card-price'>${getPrecioNeto(producto)}</Card.Text>
                  </Card.Body>
               </Card>
            </Link>
         </div>
      ));
   };

   const handleCantidadChange = ({ currentTarget: input }: React.FormEvent<HTMLSelectElement>) => {
      let productoMapped = {
         ...producto,
         cantidad: Number(input.value),
      };
      setProducto(productoMapped);
   };

   const handleAgregarProducto = async (productoData: any) => {
      let carrito: any[] = [];
      let productoMapped = mapProductoToCarritoModel(productoData);
      try {
         carrito = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart') as string) : [];
         // Checar si ya existe en el carrito
         const productoIndex: number = carrito.findIndex(producto => producto.id === productoMapped.id);
         if (productoIndex > -1) {
            setGenericAlert({
               isOpen: true,
               variant: 'warning',
               message:
                  'El producto ya se encuentra en su carrito. Ingresa a tu carrito para proceder con el pago o modificar la cantidad.',
            });
         } else {
            // Actualizar carrito
            carrito.push(productoMapped);
            localStorage.setItem('cart', JSON.stringify(carrito));
            setCartCounter(carrito.length);

            const result = await Swal.fire({
               text: 'El producto ha sido agregado al carrito.',
               icon: 'success',
               showCancelButton: true,
               confirmButtonText: 'Proceder al pago',
               cancelButtonText: 'Seguir comprando',
               reverseButtons: true,
               customClass: {
                  confirmButton: 'btn btn-primary px-5',
                  cancelButton: 'btn btn-outline-secondary px-5 mr-2',
                  content: 'confirmation-modal-text',
               },
               buttonsStyling: false,
            });
            if (result.value) {
               history.push({
                  pathname: '/proceso-pago',
                  state: { prevRoute: '/proceso-pago' },
               });
            } else {
               history.push({
                  pathname: '/productos',
                  search: `categoriaId=${productoData.linea}&subCategoriaId=${productoData.sublinea}&subSubCategoriaId=${productoData.subsublinea}`,
               });
            }
         }
      } catch (error) {
         setGenericAlert({
            isOpen: true,
            variant: 'error',
            message: 'Ocurrío un problema al agregar tu producto al carrito. Por favor intenta de nuevo.',
         });
      }
   };
   const mapProductoToCarritoModel = productoData => {
      return {
         id: productoData.id,
         imagen: productoData.imagen_url,
         descripcion: productoData.descripcion,
         codigo: productoData.codigo,
         precio: productoData.precio,
         cantidad: producto.cantidad,
         existencia: producto.existencia,
         promociones: productoData?.promociones,
      };
   };

   const getNombreCategoria = (categoriaId: number): string => {
      if (categorias.length > 0 && categoriaId) {
         const categoriaFound = categorias.find(categoria => categoria.id === categoriaId);
         return categoriaFound.nombre.charAt(0) + categoriaFound.nombre.substring(1).toLowerCase();
      }
      return 'Categoria';
   };

   const getCantidadOptions = () => {
      if (producto) {
         let options: any[] = [];
         for (let i = 1; i <= producto.existencia; i++) {
            options.push(<option key={i}>{i}</option>);
         }
         return options;
      }
   };

   return (
      <div className='container mt-5'>
         <Breadcrumb className='bg-white'>
            <li className='breadcrumb-item'>
               <Link to='/'>Inicio</Link>
            </li>
            <li className='breadcrumb-item'>
               <Link to={`/productos?categoriaId=${producto?.linea}`}>{getNombreCategoria(producto?.linea)}</Link>
            </li>
            {/* <li className='breadcrumb-item'>
               <Link to={`/productos?categoriaId=${producto?.linea}&subCategoriaId=${producto?.sublinea}`}>{getNombreCategoria(producto?.linea)}</Link>
            </li>
            <li className='breadcrumb-item'>
               <Link to={`/productos?categoriaId=${producto?.linea}&subCategoriaId=${producto?.sublinea}&subSubCategoriaId=${producto?.subsublinea}`}>{getNombreCategoria(producto?.linea)}</Link>
            </li> */}
            <li className='breadcrumb-item active' aria-current='page'>
               {producto?.descripcion}
            </li>
         </Breadcrumb>
         {genericAlert.isOpen ? (
            <RBAlert className='text-left' variant={genericAlert.variant}>
               {genericAlert.variant === 'success' ? (
                  <FontAwesomeIcon icon={faCheckCircle} size='lg' className='mr-2' />
               ) : (
                  <FontAwesomeIcon icon={faExclamationCircle} size='lg' className='mr-2' />
               )}
               {genericAlert.message}
            </RBAlert>
         ) : null}
         <div className='row'>
            <div className='col-md-5'>
               <div
                  className='product-image'
                  style={{
                     backgroundSize: 'contain',
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: 'center center',
                     backgroundImage: `url(${producto?.imagen_url ? producto.imagen_url : PRODUCTO_IMAGE_PLACEHOLDER})`,
                     height: '215px',
                  }}></div>
            </div>
            <div className='col-md-7 text-center text-md-left'>
               <h4 className='product-name'>{producto?.descripcion}</h4>
               <p className='product-code'>Código: {producto?.codigo}</p>
               <p className='product-price mb-0'>${getPrecioNeto(producto, producto?.cantidad)}</p>
               <p>
                  Envío a domicilio <span className='font-weight-bold'>GRATIS</span> en pedidos mayores a $500.00
               </p>
               {producto?.existencia > 0 ? (
                  <Form>
                     <Form.Group controlId='quantity'>
                        <Form.Label className='d-block'>Cantidad</Form.Label>
                        <Form.Control
                           name={`cantidad-${producto?.id}`}
                           as='select'
                           custom
                           onChange={handleCantidadChange}
                           value={producto?.cantidad}>
                           {getCantidadOptions()}
                        </Form.Control>
                        <Button
                           variant='primary'
                           className='ml-4 font-weight-bold'
                           onClick={() => handleAgregarProducto(producto)}>
                           <FontAwesomeIcon icon={faCartPlus} size='lg' className='mr-2' /> Agregar al carrito
                        </Button>
                     </Form.Group>
                  </Form>
               ) : (
                  <p className='text-danger'>
                     Producto no disponible en línea, contactanos via Whatsapp (631-144-0106) para más información.
                  </p>
               )}
               <div className='product-description-header'>Características</div>
               <hr className='mt-0' />
               <p className='text-justify'>
                  {producto?.descripcion_ecommerce || 'Por el momento no hay una descripción del producto'}
               </p>
            </div>
         </div>

         <div className='text-center my-5'>
            <header>
               <h4>
                  <span className='header-text px-5'>Productos similares</span>
               </h4>
            </header>
            {getProductosSimilares().length > 0 ? (
               <div className='row mt-5'>{getProductosSimilares()}</div>
            ) : (
               <div className='mt-5 py-5 text-center'>No se encontraron productos similares.</div>
            )}
         </div>
      </div>
   );
};

export default Producto;

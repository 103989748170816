import React from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { logout } from 'services/authentication.service';
// import logo from '../../../assets/images/logo.png';

export interface NavbarProps {
   user: any;
}

const TopNavbar: React.FC<NavbarProps> = ({ user }) => {
   let history = useHistory();

   return (
      <Navbar bg='light'>
         <Nav className='mr-auto'>{/* <img className='logo img-fluid mr-3' src={logo} alt='Logo' /> */}</Nav>
         <Nav className='ml-auto'>
            <NavDropdown
               title={
                  <>
                     <FontAwesomeIcon icon={faUserCircle} size='lg' className='mr-2' />
                     {`${user.nombres}`}
                  </>
               }
               alignRight
               id='basic-nav-dropdown'>
               <NavDropdown.Item
                  onClick={() => {
                     logout();
                     history.replace('/');
                  }}>
                  <FontAwesomeIcon icon={faSignOutAlt} size='lg' className='mr-2' />
                  Cerrar sesión
               </NavDropdown.Item>
            </NavDropdown>
         </Nav>
      </Navbar>
   );
};

export default TopNavbar;

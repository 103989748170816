import { useEffect } from 'react';

function useScrollTop(id: string = 'root') {
   useEffect(() => {
      const element = document.getElementById(id)!;
      element?.scroll({
         top: 0,
      });
   }, [id]);

   return null;
}

export default useScrollTop;

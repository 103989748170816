import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import RBAlert from 'react-bootstrap/Alert';
import RBForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowLeft, faTrashAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
// Mis Componentes
import ErrorFeedback from 'components/shared/ErrorFeedback';
import http from 'services/http.service';
import { getCurrentUser } from 'services/authentication.service';
// import { Alert } from 'typings';
import './Perfil.css';

export interface DireccionesEnvioProps {}

const DireccionesEnvio: React.FC<DireccionesEnvioProps> = () => {
   const usuario = getCurrentUser();
   const [direcciones, setDirecciones] = useState<any[]>([]);
   const [loading, setLoading] = useState<boolean>(false);
   const [showModal, setShowModal] = useState(false);
   const [genericAlert, setGenericAlert] = useState<any>({
      isOpen: false,
      variant: '',
      message: '',
   });
   const initialValues = {
      nombre_recibe: '',
      telefono: '',
      calle: '',
      numero: '',
      colonia: '',
      ciudad: 'Nogales',
      estado: 'Sonora',
      codigo_postal: '',
      referencias: '',
   };
   const direccionEnvioSchema = Yup.object().shape({
      nombre_recibe: Yup.string().max(200, 'Máximo 200 caracteres').trim().required('Campo requerido'),
      telefono: Yup.string()
         .matches(/^\d{7,11}$/, 'De 7 a 11 digitos. Solo números.')
         .trim()
         .required('Campo requerido'),
      calle: Yup.string().max(200, 'Máximo 200 caracteres').trim().required('Campo requerido'),
      numero: Yup.string().min(1).max(10, 'Máximo 10 caracteres').trim().required('Campo requerido'),
      colonia: Yup.string().max(100, 'Máximo 100 caracteres').trim().required('Campo requerido'),
      codigo_postal: Yup.string()
         .matches(/^\d{4,5}$/, 'Ingrese un Codigo Postal válido')
         .notRequired(),
      referencias: Yup.string().max(200, 'Máximo 200 caracteres').trim().required('Campo requerido'),
   });

   useEffect(() => {
      const fetchDirecciones = async () => {
         try {
            setLoading(true);

            const direccionesData: any[] = await http.get(`/users/${usuario.usuario_id}/direcciones`);
            setDirecciones(direccionesData);

            setLoading(false);
         } catch (error) {
            setLoading(false);
            console.error(error);
         }
      };

      fetchDirecciones();
   }, []);

   const getDirecciones = (): any[] => {
      return direcciones.map((direccion, index) => (
         <Col key={direccion.direccion_id} md='4' className='mb-4'>
            <Card className='card-shadow cursor-pointer'>
               <Card.Body className='py-3'>
                  <p className='font-weight-bold text-primary'>Direccion {index + 1}</p>
                  <div className='font-weight-medium'>{`${direccion?.calle} ${direccion?.numero}, ${direccion?.colonia}`}</div>
                  <div className='font-weight-medium'>{`${direccion?.codigo_postal ?? ''} ${direccion?.Ciudad?.nombre}`}</div>
                  <div className='font-weight-medium'>Sonora</div>
                  <div className='text-danger font-weight-medium mt-3'>
                     <span
                        onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
                           handleEliminarDireccionEnvio(direccion.direccion_id)
                        }>
                        <FontAwesomeIcon icon={faTrashAlt} className='mr-3' />
                        Eliminar
                     </span>
                  </div>
               </Card.Body>
            </Card>
         </Col>
      ));
   };

   const handleEliminarDireccionEnvio = async (direccion_id: number) => {
      const result = await Swal.fire({
         // title: '¿Estas seguro de querer eliminar este método de pago?',
         text: '¿Estas seguro de querer eliminar esta dirección?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Aceptar',
         cancelButtonText: 'Cancelar',
         customClass: {
            confirmButton: 'btn btn-primary px-5',
            cancelButton: 'btn btn-outline-secondary px-5 mr-2',
            content: 'confirmation-modal-text',
         },
         reverseButtons: true,
         buttonsStyling: false,
      });
      if (result.value) {
         try {
            await http.delete(`users/${usuario.usuario_id}/direcciones/${direccion_id}`);

            setDirecciones(direcciones.filter(direccion => direccion.direccion_id !== direccion_id));
            // toast.success(<ToastMessage type={'success'}>La acción fue realizada con éxito.</ToastMessage>);
         } catch (error) {
            // toast.error(<ToastMessage type={'error'}>Ha ocurrido un error, intente de nuevo.</ToastMessage>);
            console.error(error);
         }
      }
   };

   const handleFormSubmit = async (values, actions) => {
      try {
         Swal.fire('Procesando...', 'Espere un momento...', 'info');
         Swal.showLoading();

         const body = {
            ...(values.nombre_recibe ? { nombre_recibe: values.nombre_recibe } : {}),
            ...(values.telefono ? { telefono: values.telefono } : {}),
            calle: values.calle,
            numero: values.numero,
            colonia: values.colonia,
            ciudad: 1932, // Nogales
            estado: 26, // Sonora
            ...(values.codigo_postal ? { codigo_postal: values.codigo_postal } : {}),
            ...(values.referencias ? { referencias: values.referencias } : {}),
         };
         const newDireccion = await http.post(`users/${usuario.usuario_id}/direcciones`, body);

         setDirecciones(direcciones.concat(newDireccion));

         Swal.close();
         setShowModal(false);
         actions.setSubmitting(false);
      } catch (error) {
         Swal.close();
         actions.setSubmitting(false);
         setGenericAlert({
            isOpen: true,
            variant: 'danger',
            message: error.message,
         });
      }
   };

   const handleAgregarDireccionEnvio = () => {
      setShowModal(true);
   };

   const handleCloseModal = () => {
      setShowModal(false);
      setGenericAlert({
         isOpen: false,
         variant: '',
         message: '',
      });
   };
   return (
      <div className='perfil'>
         <div className='container mt-5'>
            <h5>
               <Link to='/perfil' className='text-dark p-3'>
                  <FontAwesomeIcon icon={faArrowLeft} className='' />
               </Link>
               Direcciones de envío
            </h5>
            <hr />

            <Button variant='primary' className='font-weight-bold px-3 mb-5' onClick={handleAgregarDireccionEnvio}>
               <FontAwesomeIcon icon={faPlus} className='mr-3' />
               Nueva direccion de envío
            </Button>

            {direcciones.length > 0 ? (
               <Row>{getDirecciones()}</Row>
            ) : loading ? (
               <div className='py-5 text-center'>
                  <FontAwesomeIcon icon={faSpinner} spin size='lg' className='mr-2' />
                  Cargando...
               </div>
            ) : (
               <div className='py-5 text-center'>Agrega una dirección para continuar</div>
            )}
         </div>
         <Modal show={showModal} centered size='lg' onHide={handleCloseModal}>
            <Modal.Header closeButton>
               <Modal.Title className='text-primary font-weight-bold'>Nueva dirección</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-5 py-4'>
               {genericAlert.isOpen ? (
                  <RBAlert className='text-left' variant={genericAlert.variant}>
                     {genericAlert.message}
                  </RBAlert>
               ) : null}
               <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  validationSchema={direccionEnvioSchema}
                  onSubmit={handleFormSubmit}
                  render={({ isSubmitting }: FormikProps<any>) => (
                     <Form>
                        <Row>
                           <Col md='6'>
                              <RBForm.Group controlId='nombre_recibe'>
                                 <RBForm.Label>Nombre completo / Quien recibe</RBForm.Label>
                                 <Field name='nombre_recibe'>
                                    {({ field }) => <RBForm.Control {...field} type='text' placeholder='' />}
                                 </Field>
                                 <ErrorMessage name='nombre_recibe' component={ErrorFeedback} />
                              </RBForm.Group>
                           </Col>
                           <Col md='6'>
                              <RBForm.Group controlId='telefono'>
                                 <RBForm.Label>Teléfono / Quien recibe</RBForm.Label>
                                 <Field name='telefono'>
                                    {({ field }) => <RBForm.Control {...field} type='text' placeholder='' />}
                                 </Field>
                                 <ErrorMessage name='telefono' component={ErrorFeedback} />
                              </RBForm.Group>
                           </Col>
                           <Col md='6'>
                              <RBForm.Group controlId='calle'>
                                 <RBForm.Label>Calle</RBForm.Label>
                                 <Field name='calle'>
                                    {({ field }) => <RBForm.Control {...field} type='text' placeholder='' />}
                                 </Field>
                                 <ErrorMessage name='calle' component={ErrorFeedback} />
                              </RBForm.Group>
                           </Col>
                           <Col md='6'>
                              <RBForm.Group controlId='numero'>
                                 <RBForm.Label>Número</RBForm.Label>
                                 <Field name='numero'>
                                    {({ field }) => (
                                       <RBForm.Control {...field} type='text' placeholder='Número de casa/edificio' />
                                    )}
                                 </Field>
                                 <ErrorMessage name='numero' component={ErrorFeedback} />
                              </RBForm.Group>
                           </Col>
                           <Col md='6'>
                              <RBForm.Group controlId='colonia'>
                                 <RBForm.Label>Colonia</RBForm.Label>
                                 <Field name='colonia'>
                                    {({ field }) => <RBForm.Control {...field} type='text' placeholder='' />}
                                 </Field>
                                 <ErrorMessage name='colonia' component={ErrorFeedback} />
                              </RBForm.Group>
                           </Col>
                           <Col md='6'>
                              <RBForm.Group controlId='ciudad'>
                                 <RBForm.Label>Ciudad</RBForm.Label>
                                 <Field name='ciudad'>
                                    {({ field }) => <RBForm.Control {...field} type='text' placeholder='' disabled />}
                                 </Field>
                                 <ErrorMessage name='ciudad' component={ErrorFeedback} />
                              </RBForm.Group>
                           </Col>
                           <Col md='6'>
                              <RBForm.Group controlId='estado'>
                                 <RBForm.Label>Estado</RBForm.Label>
                                 <Field name='estado'>
                                    {({ field }) => <RBForm.Control {...field} type='text' placeholder='' disabled />}
                                 </Field>
                                 <ErrorMessage name='estado' component={ErrorFeedback} />
                              </RBForm.Group>
                           </Col>
                           <Col md='6'>
                              <RBForm.Group controlId='codigo_postal'>
                                 <RBForm.Label>Código Postal (Opcional)</RBForm.Label>
                                 <Field name='codigo_postal'>
                                    {({ field }) => <RBForm.Control {...field} type='text' placeholder='' />}
                                 </Field>
                                 <ErrorMessage name='codigo_postal' component={ErrorFeedback} />
                              </RBForm.Group>
                           </Col>
                           <Col>
                              <RBForm.Group controlId='referencias'>
                                 <RBForm.Label>Referencias</RBForm.Label>
                                 <Field name='referencias'>
                                    {({ field }) => <RBForm.Control {...field} type='text' placeholder='' />}
                                 </Field>
                                 <ErrorMessage name='referencias' component={ErrorFeedback} />
                              </RBForm.Group>
                           </Col>
                        </Row>

                        {(genericAlert.isOpen && genericAlert.variant === 'danger') ||
                        (!genericAlert.isOpen && !genericAlert.variant) ? (
                           <div className='text-center'>
                              <Button
                                 variant='primary'
                                 type='submit'
                                 className='btn-wide font-weight-bold mt-5 px-5'
                                 disabled={isSubmitting}>
                                 {isSubmitting ? <FontAwesomeIcon icon={faSpinner} pulse size='lg' /> : 'Guardar dirección'}
                              </Button>
                           </div>
                        ) : null}
                     </Form>
                  )}
               />
            </Modal.Body>
         </Modal>
      </div>
   );
};

export default DireccionesEnvio;

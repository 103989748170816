import React from 'react';
import AppRoutes from 'routes/app.routes';
import { setPublicKey } from 'services/authentication.service';

setPublicKey();

function App() {
   return <AppRoutes />;
}

export default App;

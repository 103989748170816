import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
// Mis Componentes
import http from 'services/http.service';

interface ElegirSucursalProps {
   orden: any;
   setOrden: React.Dispatch<any>;
   onBackBtnClick: () => void;
   onNextBtnClick: () => void;
}

const ElegirSucursal: React.FC<ElegirSucursalProps> = ({ orden, setOrden, onBackBtnClick, onNextBtnClick }) => {
   const [sucursales, setSucursales] = useState<any[]>([]);
   const [loading, setLoading] = useState<boolean>(false);

   useEffect(() => {
      const fetchSucursales = async () => {
         try {
            setLoading(true);

            const { rows: sucursalesData }: any = await http.get(`/sucursal`);
            setSucursales(sucursalesData);

            setLoading(false);
         } catch (error) {
            setLoading(false);
            console.error(error);
         }
      };

      fetchSucursales();
   }, []);

   const getSucursales = (): JSX.Element[] => {
      return sucursales.map((sucursal, index) => (
         <Col key={sucursal.sucursal_id} md='4'>
            <Card
               className={`card-shadow cursor-pointer h-100 ${
                  orden.sucursal?.sucursal_id === sucursal.sucursal_id ? 'active-address' : ''
               }`}
               onClick={() =>
                  setOrden({
                     ...orden,
                     sucursal,
                  })
               }>
               <Card.Body className='py-3'>
                  <p className='font-weight-bold text-primary'>Sucursal {index + 1}</p>
                  <div className='font-weight-medium'>{`${sucursal?.calle} ${sucursal?.numero}, ${sucursal?.colonia}`}</div>
                  <div className='font-weight-medium'>{`${sucursal?.codigo_postal ?? ''} ${sucursal?.Ciudad?.nombre},`}</div>
                  <div className='font-weight-medium'>Sonora</div>
               </Card.Body>
            </Card>
         </Col>
      ));
   };

   return (
      <>
         <p className='font-weight-bold'>Elige una sucursal para recoger tu pedido</p>

         {sucursales.length > 0 ? (
            <Row>{getSucursales()}</Row>
         ) : loading ? (
            <div className='py-5 text-center'>
               <FontAwesomeIcon icon={faSpinner} spin size='lg' className='mr-2' />
               Cargando...
            </div>
         ) : (
            <div className='py-5 text-center'>No se encontro la información de las sucursales</div>
         )}

         <Row className='mt-5'>
            <Col md='6' className='text-center d-md-flex justify-content-md-end mb-4 mb-md-0'>
               <Button variant='light' className='font-weight-bold px-5 btn-stepper text-primary' onClick={onBackBtnClick}>
                  Atras
               </Button>
            </Col>
            <Col md='6' className='text-center text-md-left'>
               <Button variant='primary' className='font-weight-bold px-5 btn-stepper' onClick={onNextBtnClick}>
                  Siguiente
               </Button>
            </Col>
         </Row>
      </>
   );
};

export default ElegirSucursal;

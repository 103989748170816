import React, { useState, useEffect } from 'react';
import { /* Link, */ useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import Card from 'react-bootstrap/Card';
import RBAlert from 'react-bootstrap/Alert';
import RBForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// Mis componentes
import ErrorFeedback from 'components/shared/ErrorFeedback';
import http from 'services/http.service';
import { Alert } from 'typings';

export interface ChangePasswordProps { }

const ChangePassword: React.FC<ChangePasswordProps> = () => {
	let history = useHistory();
	let { token } = useParams();
	const [errorAlert, setErrorAlert] = useState<Alert>({
		isOpen: false,
		message: '',
	});
	const [successAlert, setSuccessAlert] = useState<Alert>({
		isOpen: false,
		message: '',
	});
	const initialValues = {
		newPassword: '',
		confirmPassword: '',
	};

	const newPasswordSchema = Yup.object().shape({
		newPassword: Yup.string().min(6, 'Mínimo 6 caracteres').max(50, 'Maximo 50 caracteres').required('Campo requerido'),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('newPassword'), null], 'Las contraseñas no coinciden')
			.required('Campo requerido'),
	});

	useEffect(() => {
		const checkToken = async () => {
			try {
				await http.get(`auth/forgot/${token}`);
			} catch (error) {
				if (error.type === 'Auth/TokenNotFound') {
					setErrorAlert({
						isOpen: true,
						message: 'El link para recuperar contraseña ha caducado.',
					});
				}
				if (error.type === 'Auth/AccountNotFound') {
					setErrorAlert({
						isOpen: true,
						message: 'Esta cuenta ya no existe.',
					});
				}
			}
		};

		if (token) {
			checkToken();
		}
	}, [token]);

	const handleFormSubmit = async (values, actions) => {
		try {
			await http.post(`auth/forgot/${token}`, {
				password: values.newPassword,
				confirmPassword: values.confirmPassword,
			});
			setSuccessAlert({
				isOpen: true,
				message: 'Tu contraseña se ha cambiado correctamente, serás redireccionado...',
			});
			setTimeout(() => {
				history.replace('/entrar');
			}, 3000);
		} catch (error) {
			actions.setSubmitting(false);
			if (error.type === 'Auth/TokenNotFound') {
				setErrorAlert({
					isOpen: true,
					message: 'El link para recuperar contraseña ha caducado.',
				});
			}
		}
	};

	const handleCloseAlert = () => {
		if (errorAlert.isOpen) {
			setErrorAlert({
				isOpen: false,
				message: '',
			});
		} else if (successAlert.isOpen) {
			setSuccessAlert({
				isOpen: false,
				message: '',
			});
		}
	};

	return (
		<>
			<div className='auth'>
				<Card className='auth-card'>
					<Card.Header className='bg-white text-center text-primary'>Cambiar contraseña</Card.Header>
					<Card.Body className='px-5 pb-5'>
						{errorAlert.isOpen ? (
							<RBAlert variant='danger' onClose={handleCloseAlert} dismissible>
								<p className='mb-0'>{errorAlert.message}</p>
							</RBAlert>
						) : null}
						{successAlert.isOpen ? (
							<RBAlert variant='success'>
								<p className='mb-0'>{successAlert.message}</p>
							</RBAlert>
						) : null}
						<Formik initialValues={initialValues} validationSchema={newPasswordSchema} onSubmit={handleFormSubmit}>
							{({ isSubmitting }: FormikProps<any>) => (
								<Form>
									<RBForm.Group controlId='newPassword'>
										<RBForm.Label>Contraseña</RBForm.Label>
										<Field name='newPassword'>
											{({ field }) => (
												<RBForm.Control {...field} type='password' placeholder='Escriba su contraseña' />
											)}
										</Field>
										<ErrorMessage name='newPassword' component={ErrorFeedback} />
									</RBForm.Group>

									<RBForm.Group controlId='confirmPassword'>
										<RBForm.Label>Confirmar contraseña</RBForm.Label>
										<Field name='confirmPassword'>
											{({ field }) => <RBForm.Control {...field} type='password' placeholder='Repita su contraseña' />}
										</Field>
										<ErrorMessage name='confirmPassword' component={ErrorFeedback} />
									</RBForm.Group>

									<Button
										variant='primary'
										className='mt-5 font-weight-bold'
										type='submit'
										block
										disabled={isSubmitting}>
										Cambiar contraseña
                           </Button>
								</Form>
							)}
						</Formik>
					</Card.Body>
				</Card>
			</div>
		</>
	);
};

export default ChangePassword;

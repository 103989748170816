import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import '../ProcesoPagoStepper.css';

interface AgradecimientoProps {}

const Agradecimiento: React.FC<AgradecimientoProps> = () => {
   let history = useHistory();
   return (
      <div className='agradecimiento'>
         <div className='container mt-5'>
            <Row className='mt-4 mb-5'>
               <Col>
                  <Card className='agradecimiento-card card-shadow mb-5 mb-md-0 mx-auto'>
                     <Card.Body className='text-center position-relative'>
                        <h4 className='text-primary font-weight-bold mb-4'>¡Gracias por tu compra!</h4>
                        <div className='font-weight-medium mb-3 agradecimiento-text-main mx-auto text-center'>
                           Hemos enviado la información de tu pedido a tu correo electrónico.
                        </div>
                        <hr style={{ width: '143.44px' }} />
                        <p className='font-weight-medium'>Cualquier duda o aclaraciones:</p>
                        <div className='font-weight-medium text-primary mb-2'>
                           <FontAwesomeIcon icon={faEnvelope} size='lg' className='mr-2' /> ventasweb@plomeriamedina.com
                        </div>
                        <div className='font-weight-medium text-primary mb-2'>
                           <FontAwesomeIcon icon={faPhone} size='lg' className='mr-3' />
                           631-144-0106
                        </div>
                        <div className='font-weight-medium text-primary'>
                           <FontAwesomeIcon icon={faPhone} size='lg' className='mr-3' />
                           631-314-7130
                        </div>
                        <Button
                           variant='primary'
                           className='font-weight-bold px-5 btn-thanks position-absolute'
                           onClick={() => history.replace('/productos')}>
                           Volver a la tienda
                        </Button>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>
      </div>
   );
};

export default Agradecimiento;

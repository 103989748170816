import React from 'react';

export interface PoliticasDevolucionProps { }

const PoliticasDevolucion: React.FC<PoliticasDevolucionProps> = () => {
	return (
		<section id='politicas-devolucion'>
			<div className="container my-5">
				<h1 className="text-center">Políticas de devoluciones</h1>
				<p className="text-justify">
					Los productos comprados en MEDINAFERRETERIAS.COM pueden ser devueltos en
					un plazo no mayor a 30 días después de la fecha de compra.
				</p>

				<p className="font-weight-bold">Requisitos</p>
				<p className="text-justify">
					Para realizar un cambio o devolución será necesario:
					<ul>
						<li>Ticket de compra.</li>
						<li>Mercancía en su empaque original.</li>
					</ul>
				</p>

				<p className="font-weight-bold">Reembolso de la compra en línea</p>
				<p className="text-justify">
					El reembolso se entregara en la misma forma de pago que se haya efectuado la
					compra. (tarjeta, efectivo o transferencia)
				</p>
				<p className="text-justify">
					Cuando la compra se hizo con tarjeta, La devolución se reflejara en la cuenta del
					cliente en un periodo de 15 a 30 días hábiles según las condiciones del banco del
					cliente.
				</p>

				<p className="font-weight-bold">Restricciones para cambios de mercancía</p>
				<p className="text-justify">
					Restricciones para realizar reembolso o cambio de mercancía.
					<ol>
						<li>No se aceptan devoluciones mayores a 30 días.</li>
						<li>
							No se aceptan devoluciones por productos dañados por mala instalación o uso
							inapropiado.
						</li>
						<li>No se aceptan devoluciones por productos incompletos.</li>
						<li>
							No se aceptan en productos recortados: tubería, cable eléctrico, cadena,
							manguera, maderas.
						</li>
						<li>
							No se aceptan devoluciones de productos perecederos que se encuentren
							abiertos: pegamentos, pinturas, barnices, etc.
						</li>
						<li>
							No se aceptan devoluciones por productos no aplicables a garantía con
							proveedor.
						</li>
						<li>Ordenes especiales.</li>
					</ol>
				</p>

				<p className="text-justify">
					Antes de generar una devolución o cambio, se verificará que el artículo cumpla con las
					condiciones de devolución.
				</p>
				<p className="text-justify font-weight-bold">
					Las políticas y condiciones de devoluciones están sujetas a cambios sin previo
					aviso.
				</p>
			</div>
		</section>
	);
};

export default PoliticasDevolucion;

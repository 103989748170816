import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import http from 'services/http.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
// Mis componentes
import useScrollTop from 'hooks/useScrollTop';
import { getPrecioNeto } from 'utils';
import { PRODUCTO_IMAGE_PLACEHOLDER } from 'utils/constants';

export interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
   const [productosCategoria1, setProductosCategoria1] = useState<any[]>([]);
   const [productosCategoria2, setCategoria2] = useState<any[]>([]);
   const [nuevosProductos, setNuevosProductos] = useState<any[]>([]);
   const [carouselImages, setCarouselImages] = useState<any[]>([]);
   const [banners, setBanners] = useState<any[]>([]);

   useScrollTop();

   useEffect(() => {
      const fetchCategoria1 = async () => {
         try {
            const params = {
               categoria: 1535,
               limit: 6,
            };
            const { results: categoria1Data }: any = await http.get(`productos`, { params });
            setProductosCategoria1(categoria1Data);
         } catch (error) {
            console.error(error);
         }
      };
      const fetchCategoria2 = async () => {
         try {
            const params = {
               categoria: 1534,
               limit: 6,
            };
            const { results: categoria2Data }: any = await http.get(`productos`, { params });
            setCategoria2(categoria2Data);
         } catch (error) {
            console.error(error);
         }
      };
      const fetchCategoria3 = async () => {
         try {
            const params = {
               categoria: 1541,
               limit: 6,
            };
            const { results: nuevosProductosData }: any = await http.get(`productos`, { params });
            setNuevosProductos(nuevosProductosData);
         } catch (error) {
            console.error(error);
         }
      };

      fetchCategoria1();
      fetchCategoria2();
      fetchCategoria3();
   }, []);

   useEffect(() => {
      const fetchCarouselImages = async () => {
         try {
            const { rows: carouselImagesList }: any = await http.get(`carousel`);
            setCarouselImages(carouselImagesList);
         } catch (error) {
            console.error(error);
         }
      };

      fetchCarouselImages();
   }, []);

   useEffect(() => {
      const fetchBanners = async () => {
         try {
            const { rows: banners }: any = await http.get(`banners`);
            setBanners(banners);
         } catch (error) {
            console.error(error);
         }
      };

      fetchBanners();
   }, []);

   const getCategoria1Productos = (): any[] => {
      return productosCategoria1.map(producto => (
         <div key={producto.id} className='col-md-2'>
            <Link to={`productos/${producto.id}`}>
               <Card className='product-card'>
                  <div
                     className='product-image'
                     style={{
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundImage: `url(${producto.imagen_url ? producto.imagen_url : PRODUCTO_IMAGE_PLACEHOLDER})`,
                        height: '215px',
                     }}></div>
                  {/* <Card.Img variant='top' src={producto.imagen_url ? producto.imagen_url : PRODUCTO_IMAGE_PLACEHOLDER} /> */}
                  <Card.Body className='pt-3 px-1 pb-1'>
                     <div className='mb-3'>
                        <Card.Text className='text-dark product-name' title={producto.descripcion.toLowerCase()}>
                           {producto.descripcion.toLowerCase()}
                        </Card.Text>
                     </div>
                     <Card.Text className='text-primary product-card-price'>${getPrecioNeto(producto)}</Card.Text>
                  </Card.Body>
               </Card>
            </Link>
         </div>
      ));
   };
   const getCategoria2Productos = (): any[] => {
      return productosCategoria2.map(producto => (
         <div key={producto.id} className='col-md-2'>
            <Link to={`productos/${producto.id}`}>
               <Card className='product-card'>
                  <div
                     className='product-image'
                     style={{
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundImage: `url(${producto.imagen_url ? producto.imagen_url : PRODUCTO_IMAGE_PLACEHOLDER})`,
                        height: '215px',
                     }}></div>
                  {/* <Card.Img variant='top' src={producto.imagen_url ? producto.imagen_url : PRODUCTO_IMAGE_PLACEHOLDER} /> */}
                  <Card.Body className='pt-3 px-1 pb-1'>
                     <div className='mb-3'>
                        <Card.Text className='text-dark product-name' title={producto.descripcion.toLowerCase()}>
                           {producto.descripcion.toLowerCase()}
                        </Card.Text>
                     </div>
                     <Card.Text className='text-primary product-card-price'>${getPrecioNeto(producto)}</Card.Text>
                  </Card.Body>
               </Card>
            </Link>
         </div>
      ));
   };
   const getNuevosProductos = (): any[] => {
      return nuevosProductos.map(producto => (
         <div key={producto.id} className='col-md-2'>
            <Link to={`productos/${producto.id}`}>
               <Card className='product-card'>
                  <div
                     className='product-image'
                     style={{
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundImage: `url(${producto.imagen_url ? producto.imagen_url : PRODUCTO_IMAGE_PLACEHOLDER})`,
                        height: '215px',
                     }}></div>
                  {/* <Card.Img variant='top' src={producto.imagen_url ? producto.imagen_url : PRODUCTO_IMAGE_PLACEHOLDER} /> */}
                  <Card.Body className='pt-3 px-1 pb-1'>
                     <div className='mb-3'>
                        <Card.Text className='text-dark product-name' title={producto.descripcion.toLowerCase()}>
                           {producto.descripcion.toLowerCase()}
                        </Card.Text>
                     </div>
                     <Card.Text className='text-primary product-card-price'>${getPrecioNeto(producto)}</Card.Text>
                  </Card.Body>
               </Card>
            </Link>
         </div>
      ));
   };
   const getCarouselImages = (): any[] => {
      return carouselImages.map(carouselImage => (
         <Carousel.Item key={carouselImage.carousel_image_id}>
            {carouselImage.url ? (
               <a href={carouselImage.url} target='_blank' rel='noopener noreferrer'>
                  <img
                     className='d-block w-100'
                     src={`${process.env.REACT_APP_BASE_URL_ASSETS}/carousel/${carouselImage.nombre_archivo}`}
                     alt='First slide'
                  />
               </a>
            ) : (
               <img
                  className='d-block w-100'
                  src={`${process.env.REACT_APP_BASE_URL_ASSETS}/carousel/${carouselImage.nombre_archivo}`}
                  alt='First slide'
               />
            )}
         </Carousel.Item>
      ));
   };
   const getBanners = (): any[] => {
      return banners.map(banner => (
         <div key={banner.banner_id} className='col-md-6'>
            {banner.url ? (
               <a href={banner.url} target='_blank' rel='noopener noreferrer'>
                  <img
                     className='img-fluid'
                     src={`${process.env.REACT_APP_BASE_URL_ASSETS}/informativos/${banner.nombre_archivo}`}
                     alt='imagen-informativa'
                  />
               </a>
            ) : (
               <img
                  className='img-fluid'
                  src={`${process.env.REACT_APP_BASE_URL_ASSETS}/informativos/${banner.nombre_archivo}`}
                  alt='imagen-informativa'
               />
            )}
         </div>
      ));
   };

   return (
      <section id='home'>
         <div className='container-fluid'>
            <Carousel>
               {getCarouselImages()}
               {/* <Carousel.Item>
                  <img className='d-block w-100' src={img1} alt='First slide' />
               </Carousel.Item>
               <Carousel.Item>
                  <img className='d-block w-100' src={img2} alt='Second slide' />
               </Carousel.Item>
               <Carousel.Item>
                  <img className='d-block w-100' src={img3} alt='Third slide' />
               </Carousel.Item> */}
            </Carousel>
         </div>
         <div className='container-fluid'>
            <div className='text-center my-5'>
               <header>
                  <h4>
                     <span className='header-text px-5'>Ferretería</span>
                  </h4>
               </header>
               {getCategoria1Productos().length > 0 ? (
                  <div className='row mt-5'>{getCategoria1Productos()}</div>
               ) : (
                  <div className='py-5 text-center'>
                     <FontAwesomeIcon icon={faSpinner} spin size='lg' className='mr-2' />
                     Cargando...
                  </div>
               )}
            </div>

            <div className='text-center my-5'>
               <header>
                  <h4>
                     <span className='header-text px-5'>Plomería</span>
                  </h4>
               </header>
               {getCategoria2Productos().length > 0 ? (
                  <div className='row mt-5'>{getCategoria2Productos()}</div>
               ) : (
                  <div className='py-5 text-center'>
                     <FontAwesomeIcon icon={faSpinner} spin size='lg' className='mr-2' />
                     Cargando...
                  </div>
               )}
            </div>

            {banners.length > 0 ? (
               <div className='row mt-5'>{getBanners()}</div>
            ) : (
               <div className='py-5 text-center'>
                  <FontAwesomeIcon icon={faSpinner} spin size='lg' className='mr-2' />
                  Cargando...
               </div>
            )}

            <div className='text-center my-5'>
               <header>
                  <h4>
                     <span className='header-text px-5'>Construcción</span>
                  </h4>
               </header>
               {getNuevosProductos().length > 0 ? (
                  <div className='row mt-5'>{getNuevosProductos()}</div>
               ) : (
                  <div className='py-5 text-center'>
                     <FontAwesomeIcon icon={faSpinner} spin size='lg' className='mr-2' />
                     Cargando...
                  </div>
               )}
            </div>
         </div>
         <section className='mision'>
            <div className='overlay'>
               <div className='container'>
                  <div className='row py-5 text-white'>
                     <div className='col-md-5 align-self-center px-4 py-5 mision-container'>
                        <div className='mision-title text-right'>
                           Medina Ferreterías:
                           <br /> comprometidos con tu éxito
                        </div>
                     </div>
                     <div className='col-md-7 p-4'>
                        <p>
                           Nuestro arduo esfuerzo y filosofía de amor al trabajo nos han permitido cosechar éxitos en conjunto con
                           nuestros clientes quienes con nosotros encuentran los productos que necesitan.
                        </p>
                        <p>
                           Estamos comprometidos en ofrecer solo los mejores productos y a contar con un grupo de asesores
                           especializados para que siempre realices la mejor compra.
                        </p>
                        <p>
                           Contáctanos, somos la opción más adecuada para adquirir artículos de plomería y ferretería en Nogales,
                           Sonora.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </section>
   );
};

export default Home;

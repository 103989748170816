import React, { useCallback, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { Badge, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faRetweet, faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { Pagination } from 'typings/tablas';
import { METODOS_PAGO, ORDEN_STATUS, ORDEN_STATUS_NOMBRE } from 'utils/constants';

export interface OrdenesTablaProps {
   ordenes: any[];
   isTableLoading: boolean;
   pagination: Pagination;
   onTableChange: (
      type: any,
      {
         page,
         sizePerPage,
      }: {
         page: any;
         sizePerPage: any;
      }
   ) => void;
   showOrden: (orden) => void;
   onMarcarEntregada: (id: number) => Promise<void>;
   onReembolsar: (id: number, conekta_orden_id: string) => Promise<void>;
}

const OrdenesTabla: React.FC<OrdenesTablaProps> = ({
   ordenes,
   isTableLoading,
   pagination,
   onTableChange,
   showOrden,
   onMarcarEntregada,
   onReembolsar,
}) => {
   const estatusBadgeFormatter = useCallback(estatus => {
      switch (estatus) {
         case ORDEN_STATUS_NOMBRE[ORDEN_STATUS.PENDIENTE]:
            return <Badge variant='secondary'>{estatus}</Badge>;
         case ORDEN_STATUS_NOMBRE[ORDEN_STATUS.PAGADA]:
            return <Badge variant='warning'>{estatus}</Badge>;
         case ORDEN_STATUS_NOMBRE[ORDEN_STATUS.REEMBOLSADA]:
            return <Badge variant='danger'>{estatus}</Badge>;
         case ORDEN_STATUS_NOMBRE[ORDEN_STATUS.ENTREGADA]:
            return <Badge variant='success'>{estatus}</Badge>;
         default:
            return <Badge variant='warning'>N/D</Badge>;
      }
   }, []);

   const optionsFormatter = useCallback(
      (cell, row, rowIndex) => {
         return (
            <>
               {row.options.estatus === ORDEN_STATUS.PENDIENTE &&
                  [METODOS_PAGO.EFECTIVO, METODOS_PAGO.EFECTIVO_DOMICILIO, METODOS_PAGO.EFECTIVO_SUCURSAL].includes(
                     row.options.metodo_pago_id
                  ) && (
                     <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}>Marcar Entregada</Tooltip>}>
                        <Button variant='primary' className='mr-2' onClick={() => onMarcarEntregada(row.options.orden_id)}>
                           <FontAwesomeIcon icon={faBoxOpen} />
                        </Button>
                     </OverlayTrigger>
                  )}
               {row.options.estatus === ORDEN_STATUS.PAGADA &&
                  [METODOS_PAGO.TARJETA, METODOS_PAGO.PAYPAL].includes(row.options.metodo_pago_id) && (
                     <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}>Marcar Entregada</Tooltip>}>
                        <Button variant='primary' className='mr-2' onClick={() => onMarcarEntregada(row.options.orden_id)}>
                           <FontAwesomeIcon icon={faBoxOpen} />
                        </Button>
                     </OverlayTrigger>
                  )}
               {/* Si esta pagada y el metodo de entrega fue a domicilio se muestra boton de reembolso */}
               {row.options.estatus === ORDEN_STATUS.PAGADA && row.options.metodo_pago_id === METODOS_PAGO.TARJETA && (
                  <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}>Reembolsar</Tooltip>}>
                     <Button
                        variant='danger'
                        className='mr-2'
                        onClick={() => onReembolsar(row.options.orden_id, row.options.conekta_orden_id)}>
                        <FontAwesomeIcon icon={faRetweet} />
                     </Button>
                  </OverlayTrigger>
               )}
               <Button variant='outline-primary' onClick={() => showOrden(row.options)}>
                  <FontAwesomeIcon icon={faList} size='lg' className='mr-2' />
                  Detalles
               </Button>
            </>
         );
      },
      [onMarcarEntregada, onReembolsar]
   );

   const columns = useMemo(() => {
      return [
         {
            dataField: 'orden_id',
            text: 'ID #',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
         },
         {
            dataField: 'cliente',
            text: 'Cliente',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
         },
         {
            dataField: 'metodoEntrega',
            text: 'Metodo Entrega',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
         },
         {
            dataField: 'metodoPago',
            text: 'Metodo Pago',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
         },
         {
            dataField: 'estatus',
            text: 'Estatus',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
            formatter: estatusBadgeFormatter,
         },
         {
            dataField: 'fechaCompra',
            text: 'Fecha de compra',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
         },
         {
            dataField: 'options',
            text: 'Opciones',
            isDummyField: true,
            headerAlign: 'center',
            align: 'right',
            headerClasses: 'font-weight-bold text-dark',
            formatter: optionsFormatter,
         },
      ];
   }, [estatusBadgeFormatter, optionsFormatter]);

   return (
      <BootstrapTable
         bootstrap4
         striped
         condensed
         classes='ordenes-table'
         keyField='orden_id'
         data={ordenes}
         columns={columns}
         remote={{
            pagination: true,
            search: true,
         }}
         loading={isTableLoading}
         overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
         hover
         bordered={false}
         noDataIndication='No se encontraron registros.'
         rowClasses={''}
         pagination={paginationFactory({
            page: pagination.page, // Specify the current page.
            totalSize: pagination.totalSize, // Total data size.
            sizePerPage: pagination.sizePerPage, // Specify the size per page.
            withFirstAndLast: false, // hide the going to first and last page button
            alwaysShowAllBtns: true, // always show the next and previous page button
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            hideSizePerPage: true, // hide the size per page dropdown
            hidePageListOnlyOnePage: true,
         })}
         onTableChange={onTableChange}
      />
   );
};

export default OrdenesTabla;

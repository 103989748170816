import axios from 'axios';
import Swal from 'sweetalert2';
import { getToken, isTokenExpired, refreshToken } from './authentication.service';
import apiErrorHandler from './apiErrorHandler.service';

async function setupToken() {
   if (isTokenExpired()) {
      await refreshToken();
   }
   return getToken();
}

// Se crea instancia http con valores default
const httpInstance = axios.create({
   baseURL: process.env.REACT_APP_BASE_URL,
});

// Interceptor para setear el token en el request
httpInstance.interceptors.request.use(async config => {
   try {
      const token = await setupToken();
      if (token) {
         config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
   } catch (error) {
      return Promise.reject(error);
   }
});

// Interceptor para enviar datos recibidos y checar errores
httpInstance.interceptors.response.use(
   response => {
      if (response.headers['content-type'] && response.headers['content-type'].indexOf('application/json') !== -1) {
         return response.data;
      }
      return response;
   },
   error => {
      if (error.response) {
         const expectedError = error.response.status >= 400 && error.response.status < 500;
         // Error de server
         if (!expectedError && process.env.NODE_ENV === 'production') {
            apiErrorHandler('Server', error);
            return Promise.reject(error.response);
         }
         // Error de cliente
         return Promise.reject(error.response.data);
      } else {
         // Error de conexion
         if (!Swal.isVisible()) {
            Swal.fire('Error de conexión', 'Verifica tu conexión a internet.', 'error');
         }
      }
      // Se reenvia el error al bloque catch donde se realizo la llamada
      return Promise.reject(error);
   }
);

export default httpInstance;

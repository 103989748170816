import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/free-solid-svg-icons';
// import Swal from 'sweetalert2';
// Mis Componentes
import Banner from './Banner';
import http from 'services/http.service';

export interface BannersProps {}

const Banners: React.FC<BannersProps> = () => {
   const [banners, setBanners] = useState<any[]>([
      {
         banner_id: undefined,
         url: '',
         posicion: 0,
         banner: undefined,
         bannerSrc: undefined,
      },
      {
         banner_id: undefined,
         url: '',
         posicion: 1,
         banner: undefined,
         bannerSrc: undefined,
      },
   ]);
   const [banner, setBanner] = useState<any>();
   const [showModal, setShowModal] = useState(false);

   // Obtener la lista de grupos
   useEffect(() => {
      fetchBanners();
      // eslint-disable-next-line
   }, []);
   const fetchBanners = async () => {
      try {
         const { rows: BannersList }: any = await http.get('banners/');
         if (BannersList.length > 0) {
            const BannerList: any[] = BannersList.map((bannerl, index) => {
               return {
                  banner_id: bannerl.banner_id,
                  url: bannerl.url,
                  posicion: index,
                  banner: undefined,
                  bannerSrc: `${process.env.REACT_APP_BASE_URL_ASSETS}/informativos/${bannerl.nombre_archivo}`,
               };
            });
            if (BannerList.length < 2) {
               BannerList.push({
                  banner_id: undefined,
                  url: '',
                  posicion: 1,
                  banner: undefined,
                  bannerSrc: undefined,
               });
            }
            setBanners(BannerList);
         }
      } catch (error) {
         console.error(error);
      }
   };

   const showBannerModal = banner => {
      setBanner(banner);
      setShowModal(true);
   };

   const handleHideModal = () => {
      setShowModal(false);
   };

   return (
      <section id='ordenes'>
         <h3 className='font-weight-bold'>Banners publicitarios</h3>
         <Card>
            <Card.Body>
               <Row className=''>
                  {banners.map(banner => (
                     <Col md='6'>
                        <Card className='card-shadow'>
                           <Card.Body className='p-0 bg-dark-gray banner-preview' onClick={() => showBannerModal(banner)}>
                              <label className='w-100 h-100 text-center d-flex justify-content-center align-items-center mb-0 cursor-pointer'>
                                 {banner?.bannerSrc ? (
                                    <img
                                       id={`${banner.posicion}-thumbnail`}
                                       className='img-fluid'
                                       src={banner.bannerSrc}
                                       alt={`banner-${banner.posicion}`}
                                    />
                                 ) : (
                                    <div className='py-5'>
                                       <span>
                                          <FontAwesomeIcon icon={faFileImage} size='lg' className='mr-2' />
                                       </span>
                                       <span>Elegir imagen</span>
                                    </div>
                                 )}
                              </label>
                           </Card.Body>
                        </Card>
                     </Col>
                  ))}
               </Row>

               <Banner banner={banner} showModal={showModal} onHideModal={handleHideModal} reload={fetchBanners} />
            </Card.Body>
         </Card>
      </section>
   );
};

export default Banners;

import React from 'react';
import { NavLink } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faShippingFast, faImage, faImages, faUsers } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';

export interface SidebarProps {
   onToggleClick: () => void;
   toggled: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ toggled, onToggleClick }) => {
   return (
      <div className={['sidebar', toggled ? 'collapsed' : ''].join(' ')}>
         <header className='sidebar-header' style={{ height: '55px' }}>
            <h3 className='text-uppercase'>horarios</h3>
            <button type='button' className='btn btn-outline-light btn-toggle' onClick={onToggleClick}>
               <FontAwesomeIcon icon={faBars} />
            </button>
         </header>
         <ListGroup className='sidebar-links list-group-flush'>
            <NavLink className='mt-3' to='/r/ordenes' activeClassName='active'>
               <ListGroup.Item>
                  <FontAwesomeIcon icon={faShippingFast} className='mr-2' fixedWidth />
                  <span>Ordenes</span>
               </ListGroup.Item>
            </NavLink>
            <NavLink to='/r/usuarios' activeClassName='active'>
               <ListGroup.Item>
                  <FontAwesomeIcon icon={faUsers} className='mr-2' fixedWidth />
                  <span>Usuarios</span>
               </ListGroup.Item>
            </NavLink>
            <NavLink to='/r/banners' activeClassName='active'>
               <ListGroup.Item>
                  <FontAwesomeIcon icon={faImage} className='mr-2' fixedWidth />
                  <span>Banners</span>
               </ListGroup.Item>
            </NavLink>
            <NavLink to='/r/carousel' activeClassName='active'>
               <ListGroup.Item>
                  <FontAwesomeIcon icon={faImages} className='mr-2' fixedWidth />
                  <span>Carousel</span>
               </ListGroup.Item>
            </NavLink>
         </ListGroup>
      </div>
   );
};

export default Sidebar;

import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/free-solid-svg-icons';
// import Swal from 'sweetalert2';
// Mis Componentes
import CarouselImg from './CarouselImg';
import http from 'services/http.service';

export interface CarouselImgListProps {}

const CarouselImgList: React.FC<CarouselImgListProps> = () => {
   const [carouselImages, setCarouselImages] = useState<any[]>([
      {
         carousel_image_id: undefined,
         url: '',
         posicion: 0,
         carouselImage: undefined,
         carouselImageSrc: undefined,
      },
      {
         carousel_image_id: undefined,
         url: '',
         posicion: 1,
         carouselImage: undefined,
         carouselImageSrc: undefined,
      },
   ]);
   const [carouselImage, setCarouselImage] = useState<any>();
   const [showModal, setShowModal] = useState(false);

   // Obtener la lista de grupos
   useEffect(() => {
      fetchCarouselImages();
      // eslint-disable-next-line
   }, []);
   const fetchCarouselImages = async () => {
      try {
         const { rows: carouselImagesList }: any = await http.get('carousel');
         if (carouselImagesList.length > 0) {
            const carouselImageList: any[] = carouselImagesList.map((carouselImage, index) => {
               return {
                  carousel_image_id: carouselImage.carousel_image_id,
                  url: carouselImage.url,
                  posicion: index,
                  carouselImage: undefined,
                  carouselImageSrc: `${process.env.REACT_APP_BASE_URL_ASSETS}/carousel/${carouselImage.nombre_archivo}`,
               };
            });
            if (carouselImageList.length < 3) {
               carouselImageList.push({
                  carousel_image_id: undefined,
                  url: '',
                  posicion: 1,
                  carouselImage: undefined,
                  carouselImageSrc: undefined,
               });
            }
            setCarouselImages(carouselImageList);
         }
      } catch (error) {
         console.error(error);
      }
   };

   const showCarouselImageModal = carouselImageData => {
      setCarouselImage(carouselImageData);
      setShowModal(true);
   };

   const handleHideModal = () => {
      setShowModal(false);
   };

   return (
      <section id='ordenes'>
         <h3 className='font-weight-bold'>Carousel</h3>
         <Card>
            <Card.Body>
               <Row className=''>
                  {carouselImages.map(carouselImage => (
                     <Col md='6' className='mb-4'>
                        <Card className='card-shadow'>
                           <Card.Body
                              className='p-0 bg-dark-gray carousel-preview'
                              onClick={() => showCarouselImageModal(carouselImage)}>
                              <label className='w-100 h-100 text-center d-flex justify-content-center align-items-center mb-0 cursor-pointer'>
                                 {carouselImage?.carouselImageSrc ? (
                                    <img
                                       id={`${carouselImage.posicion}-thumbnail`}
                                       className='img-fluid'
                                       src={carouselImage.carouselImageSrc}
                                       alt={`carousel-${carouselImage.posicion}`}
                                    />
                                 ) : (
                                    <div className='py-5'>
                                       <span>
                                          <FontAwesomeIcon icon={faFileImage} size='lg' className='mr-2' />
                                       </span>
                                       <span>Elegir imagen</span>
                                    </div>
                                 )}
                              </label>
                           </Card.Body>
                        </Card>
                     </Col>
                  ))}
               </Row>

               <CarouselImg
                  image={carouselImage}
                  showModal={showModal}
                  onHideModal={handleHideModal}
                  reload={fetchCarouselImages}
               />
            </Card.Body>
         </Card>
      </section>
   );
};

export default CarouselImgList;

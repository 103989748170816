import { Option } from 'typings';
import { IVA, IVA_PORCENTAJE } from './constants';
import amexIcon from 'assets/images/amex.svg';
import visaIcon from 'assets/images/visa.svg';
import mastercardIcon from 'assets/images/mastercard.svg';

export const mapOptionsToViewModel = (options: any[]): Option[] => {
   return options?.map(option => {
      return {
         value: option[Object.keys(option)[0]],
         label: `${option.nombre} ${option.apellidos}`,
      };
   });
};

export const generateYears = () => {
   let years: number[] = [];
   let startYear = new Date().getFullYear();
   const lastYear = new Date().getFullYear() + 10;
   for (startYear; startYear <= lastYear; startYear++) {
      years.push(startYear);
   }
   return years;
};

export const getBrandIcon = (brandName: string): string => {
   switch (brandName) {
      case 'visa':
         return visaIcon;
      case 'mastercard':
         return mastercardIcon;
      case 'american_express':
         return amexIcon;
      default:
         return visaIcon;
   }
};

export const capitalize = (text: string) => {
   return text?.charAt(0) + text?.substring(1).toLowerCase();
};

export const truncate = (text: string) => {
   return text?.length > 59 ? `${text?.substring(0, 59)}...` : text;
};

// Precio con impuestos incluidos
export const getPrecioNeto = (producto: any, cantidad: number = 1) => {
   if (producto?.promociones?.length > 0 && cantidad >= parseInt(producto?.promociones[0]?.cantidad)) {
      const precioBruto = Number(producto?.promociones[0]?.valor_unitario);
      return precioBruto ? Number((precioBruto * IVA).toFixed(2)) * cantidad : 0.0;
   }
   return producto?.precio ? Number((producto?.precio * IVA).toFixed(2)) * cantidad : 0.0;
};

// Precio sin impuestos
export const getPrecioBruto = (producto: any, cantidad: number = 1) => {
   if (producto?.promociones?.length > 0 && cantidad >= parseInt(producto?.promociones[0]?.cantidad)) {
      const precioBruto = Number(producto?.promociones[0]?.valor_unitario);
      return precioBruto ? Number(precioBruto.toFixed(2)) * cantidad : 0.0;
   }
   return producto?.precio ? Number(producto?.precio.toFixed(2)) * cantidad : 0.0;
};

// Importe total
export const getImporteTotal = (subtotal: number) => {
   return Number((subtotal + subtotal * IVA_PORCENTAJE).toFixed(2));
};

// IVA total
export const getIVATotal = (subtotal: number) => {
   return Number((subtotal * IVA_PORCENTAJE).toFixed(2));
};

import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import Card from 'react-bootstrap/Card';
import RBAlert from 'react-bootstrap/Alert';
import RBForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
// Mis componentes
import ErrorFeedback from 'components/shared/ErrorFeedback';
import { storeToken } from 'services/authentication.service';
import { getCurrentUser } from 'services/authentication.service';
import http from 'services/http.service';
import { Alert } from 'typings';
// import { updateAbilities } from 'services/authorization.service';
import './Login.css';

export interface LoginProps {
   setUser: React.Dispatch<any>;
}

const Login: React.FC<LoginProps> = ({ setUser }) => {
   let location = useLocation();
   let history = useHistory();

   const [errorAlert, setErrorAlert] = useState<Alert>({
      isOpen: false,
      message: '',
   });
   const initialValues = {
      email: '',
      password: '',
   };

   const loginSchema = Yup.object().shape({
      email: Yup.string().email('Escriba un email válido').required('Campo requerido'),
      password: Yup.string().min(6, 'Mínimo 6 caracteres').max(50, 'Maximo 50 caracteres').required('Campo requerido'),
   });

   // const toggleShowPassword = () => {
   //    setShowPassword(prevState => !prevState);
   // };

   const handleFormSubmit = async (values, actions) => {
      try {
         const body = {
            email: values.email,
            password: values.password,
         };
         const resp: any = await http.post('auth/login', body);
         storeToken(resp.token);

         redirectUser();
      } catch (error) {
         actions.setSubmitting(false);
         if (error.type === 'Auth/BadEmailOrPassword') {
            setErrorAlert({
               isOpen: true,
               message: error.message,
            });
         }
      }
   };

   const handleCloseAlert = () => {
      setErrorAlert({
         isOpen: false,
         message: '',
      });
   };

   const redirectUser = () => {
      const usuario = getCurrentUser();
      setUser(usuario);
      if (usuario.is_admin) {
         history.replace('/r/ordenes');
      } else {
         history.replace(location.state?.prevRoute || '/');
      }
   };

   return (
      <div className='auth'>
         <Card className='auth-card'>
            <Card.Header className='bg-white text-center text-primary'>Inicio de sesión</Card.Header>
            <Card.Body className='px-5 pb-5'>
               <div className=''>
                  {errorAlert.isOpen ? (
                     <RBAlert variant='danger' onClose={handleCloseAlert} dismissible>
                        <p>{errorAlert.message}</p>
                     </RBAlert>
                  ) : null}
                  <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={handleFormSubmit}>
                     {({ isSubmitting, handleSubmit, errors }: FormikProps<any>) => (
                        <Form>
                           <RBForm.Group controlId='email'>
                              <RBForm.Label>Correo electrónico</RBForm.Label>
                              <Field name='email'>
                                 {({ field }) => <RBForm.Control {...field} type='email' placeholder='nombre@correo.com.mx' />}
                              </Field>
                              <ErrorMessage name='email' component={ErrorFeedback} />
                           </RBForm.Group>

                           <RBForm.Group controlId='password'>
                              <RBForm.Label>Contraseña</RBForm.Label>
                              <Field name='password'>
                                 {({ field }) => (
                                    <RBForm.Control {...field} type='password' placeholder='Escriba su contraseña' />
                                 )}
                              </Field>
                              <ErrorMessage name='password' component={ErrorFeedback} />
                           </RBForm.Group>

                           <div className='text-center mt-4'>
                              <Link to='/recuperar-cuenta' className='text-primary font-weight-medium'>
                                 ¿Olvidaste tu contraseña?
                              </Link>
                           </div>

                           <Button
                              variant='primary'
                              className='mt-4 font-weight-bold'
                              type='submit'
                              block
                              disabled={isSubmitting}>
                              {isSubmitting ? (
                                 <FontAwesomeIcon icon={faSpinner} spin size='lg' className='mr-2' />
                              ) : (
                                 'Iniciar sesión'
                              )}
                           </Button>

                           <hr className='my-4' />

                           <p className='text-center font-weight-medium'>¿No tienes una cuenta?</p>

                           <Link to='/registro' className='btn btn-info btn-block font-weight-bold'>
                              Crear una cuenta
                           </Link>
                        </Form>
                     )}
                  </Formik>
               </div>
            </Card.Body>
         </Card>
      </div>
   );
};

export default Login;
